import { Translation } from '@scaut-sro/meepo';

class OverviewLocale {
  public static NO_FORM: Translation = { EN: 'No form to fill', CZ: 'Nemáte žádný formulář k vyplnění' };
  public static ADD_INFORMATION: Translation = { EN: 'Fillout information', CZ: 'Doplnit informace' };
  public static THANK_YOU_FOR_FILLING_THE_FORM_1: Translation = { EN: 'Thank you', CZ: 'Děkujeme' };
  public static THANK_YOU_FOR_FILLING_THE_FORM_2: Translation = { EN: 'for filling the form!', CZ: 'za vyplnění formuláře!' };
  public static WELCOME_TEXT_HEADER: Translation = {
    EN: 'Welcome to SCAUT‘s Candidate Portal',
    CZ: 'Vítejte v kandidátském portálu SCAUT',
  };
  public static WELCOME_TEXT: Translation = {
    EN: 'This portal is a secure place to collect the information needed to process your screening. '
        + 'The entire system was developed with security and ease of use in mind. '
        + 'On this screen you can see your current screening in progress - that is, '
        + 'invitations from employers who have invited you to SCAUT. '
        + 'Click on the position tile below to open an intuitive questionnaire that guides you '
        + 'through the entire information gathering process. Once you have completed it, '
        + 'you will return to this screen.',
    CZ: 'Tento portál je bezpečným místem pro sběr informací, které jsou potřeba ke zpracování Vašeho screeningu. '
        + 'Celý systém byl vyvinut s myšlenkou bezpečnosti a jednoduchého uživatelského prostředí. '
        + 'Na této obrazovce vidíte Vaše aktuálně rozpracované screeningy – tedy pozvánky od zaměstnavatelů, '
        + 'kteří Vás do SCAUTu pozvali. Kliknutím na dlaždici s pozicí si otevřete intuitivní dotazník, '
        + 'který Vás celým procesem sběru informací provede. Po dokončení se vrátíte zpět na tuto obrazovku.',
  };
  public static YOUR_OPEN_QUESTIONNAIRES_TEXT: Translation = {
    EN: 'Your open questionnaires',
    CZ: 'Aktuální rozpracované dotazníky',
  };
}

export default OverviewLocale;
