import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Text,
} from '@chakra-ui/react';
import { FiLogOut, FiSettings } from 'react-icons/all';
import { IoLanguage } from 'react-icons/io5';
import { useGetUser, useUserSettingsReduce } from '../../core/store/reducers/UserSettingsReducer';
import Translate from '../Translate/Translate';
import UserMenuLocale from '../../core/localization/translations/UserMenu.locale';
import { useChangeLanguage } from '../../build/generated-sources/service/MutationService';
import { Language } from '../../build/generated-sources/enum/Language';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

const UserMenu: React.FunctionComponent = () => {
  const { keycloak, initialized } = useKeycloak();
  const { userProfile, language } = useGetUser();
  const history = useHistory();
  const { setLanguage } = useUserSettingsReduce();
  const [mutateLanguageChange] = useChangeLanguage({
    language: true,
  }, {
    onCompleted: (result) => {
      setLanguage(result.changeLanguage.language || Language.EN);
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value as Language;
    mutateLanguageChange({ variables: { language: lang } });
  };

  return (
    <Menu>
      <MenuButton
        as={Box}
        style={{
          cursor: 'pointer',
        }}
      >
        <Avatar
          name={`${userProfile?.firstName} ${userProfile?.lastName}`}
          src={userProfile?.avatar}
        />
      </MenuButton>
      <MenuList>
        <Flex p={4}>
          <Avatar
            size="sm"
            name={`${userProfile?.firstName} ${userProfile?.lastName}`}
            src={userProfile?.avatar}
          />
          <Flex flexDirection="column" ml={4}>
            <Text fontSize="sm">{`${userProfile?.firstName} ${userProfile?.lastName}`}</Text>
            <Text fontSize="xs" color="gray.600">{userProfile?.email}</Text>
            <Flex
              alignItems="center"
              bg="gray.200"
              borderRadius="md"
              w="max-content"
              m="6px 0"
            >
              <Icon as={IoLanguage} m="0 10px" />
              <Select
                variant="unstyled"
                size="xs"
                onChange={handleLanguageChange}
                value={language}
              >
                <option
                  value={Language.EN}
                >
                  English
                </option>
                <option
                  value={Language.CZ}
                >
                  Čeština
                </option>
              </Select>
            </Flex>
          </Flex>
        </Flex>
        <MenuDivider />
        <MenuItem
          icon={<FiSettings />}
          onClick={() => history.push('/user-settings')}
        >
          <Translate label={UserMenuLocale.USER_SETTINGS} />
        </MenuItem>
        <MenuItem
          icon={<FiLogOut />}
          onClick={() => {
            if (initialized) {
              keycloak.logout({
                redirectUri: `${window.location.protocol}//${window.location.host}`,
              });
            }
          }}
        >
          <Translate label={UserMenuLocale.LOGOUT} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
