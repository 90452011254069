import { Validate } from 'react-hook-form/dist/types/validator';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldPath, FieldPathValue } from 'react-hook-form';
import { KeycloakPasswordPolicyDto } from '../../build/generated-sources/dto/KeycloakPasswordPolicyDto';
import { KeycloakPasswordPolicy } from '../../build/generated-sources/enum/KeycloakPasswordPolicy';
import { translate } from '../localization/LocaleUtils';
import UserSettingsLocale from '../../views/UserSettings/UserSettings.locale';
import { Language } from '../../build/generated-sources/enum/Language';

const getKeycloakPasswordValidations = (passwordPolicies: KeycloakPasswordPolicyDto[],
  language: Language,
  email?: string) => {
  const validations: {
    [key:string]: Validate<FieldPathValue<FieldValues, FieldPath<FieldValues>>>
  } = {};
  passwordPolicies.forEach((policy) => {
    switch (policy.policy) {
      case KeycloakPasswordPolicy.LENGTH:
        if (policy.value && policy.value.length > 0) {
          const policyValue: number = +policy.value;
          validations.length = (value: string) => value.length >= policyValue || translate(
            UserSettingsLocale.LENGTH_ERROR, language,
          );
        }
        break;
      case KeycloakPasswordPolicy.DIGITS:
        if (policy.value && policy.value.length > 0) {
          const regexString = `(\\D*\\d){${policy.value},}`;
          const regex = new RegExp(regexString);
          validations.digits = (value: string) => regex.test(value) || translate(
            UserSettingsLocale.DIGITS_ERROR, language,
          );
        }
        break;
      case KeycloakPasswordPolicy.NOT_EMAIL:
        validations.notEmail = (value: string) => value !== email || translate(
          UserSettingsLocale.NOT_EMAIL_ERROR, language,
        );
        break;
      case KeycloakPasswordPolicy.SPECIAL_CHARS:
        if (policy.value && policy.value.length > 0) {
          const regexString = `([ \`!@#$%^&*()_+\\-=\\[\\]{};':"\\\\|,.<>\\/?~]){${policy.value},}`;
          const regex = new RegExp(regexString);
          validations.specialChars = (value: string) => regex.test(value) || translate(
            UserSettingsLocale.SPECIAL_CHARS_ERROR, language,
          );
        }
        break;
      case KeycloakPasswordPolicy.UPPER_CASE:
        if (policy.value && policy.value.length > 0) {
          const regexString = `(.*[A-Z].*){${policy.value},}`;
          const regex = new RegExp(regexString);
          validations.upperCase = (value: string) => regex.test(value) || translate(
            UserSettingsLocale.UPPER_CASE_ERROR, language,
          );
        }
        break;
      case KeycloakPasswordPolicy.LOWER_CASE:
        if (policy.value && policy.value.length > 0) {
          const regexString = `(.*[a-z].*){${policy.value},}`;
          const regex = new RegExp(regexString);
          validations.lowerCase = (value: string) => regex.test(value) || translate(
            UserSettingsLocale.LOWER_CASE_ERROR, language,
          );
        }
        break;
      case KeycloakPasswordPolicy.MAX_LENGTH:
        if (policy.value && policy.value.length > 0) {
          const policyValue: number = +policy.value;
          validations.maxLength = (value: string) => value.length <= policyValue || translate(
            UserSettingsLocale.MAX_LENGTH_ERROR, language,
          );
        }
        break;
      default:
        break;
    }
  });
  return validations;
};

export default getKeycloakPasswordValidations;
