import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { LeftMenuItem } from '@scaut-sro/meepo';
import { FiInfo } from 'react-icons/all';
import Translate from '../../components/Translate/Translate';
import { MainMenuProps } from '../../components/MainMenu/MainMenu.model';
import MainMenuLocale from '../../core/localization/translations/MainMenu.locale';
import SupportModalDetail from './SupportModalDetail';

const SupportModal: React.FunctionComponent<MainMenuProps> = (props) => {
  const {
    leftMenuWidth, headerHeight, activeItem,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="link" onClick={onOpen}>
        <LeftMenuItem
          activeItem={activeItem}
          itemId="support"
          headerHeight={headerHeight}
          leftMenuWidth={leftMenuWidth}
          icon={<FiInfo />}
        >
          <Translate label={MainMenuLocale.SUPPORT} />
        </LeftMenuItem>
      </Button>
      <SupportModalDetail
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
};

export default SupportModal;
