import { Translation } from '../localization/Localization.model';

class ScautErrorHandlerLocale {
  public static CLIENT_ERROR: Translation = {
    EN: 'There was a problem registering the user',
    CZ: 'Při registraci uživatele došlo k problému',
  };
  public static ORDER_NOT_FOUND: Translation = { EN: 'Order with ID {0} not found', CZ: 'Objednávka s ID {0} nebyla nalezena' };
  public static INVALID_DYNAMIC_FORM_INPUT: Translation = {
    EN: 'Unable to submit the execution form through dynamic form',
    CZ: 'Nelze odeslat formulář exekucí přes dynamický formulář',
  };
  public static REGISTRATION_ERROR: Translation = {
    EN: 'There was a problem registering the user',
    CZ: 'Při registraci uživatele došlo k problému',
  };
  public static ERROR_2FA_SETTINGS: Translation = {
    EN: 'There was a problem changing 2-factor settings',
    CZ: 'Při změně 2faktorového nastavení došlo k problému',
  };
  public static PAYMENT_METHOD_NOT_ALLOWED: Translation = {
    EN: 'Payment method {0} not allowed',
    CZ: 'Platební metoda {0} není povolena',
  };
  public static PAYMENT_METHOD_CHANGE_ERROR: Translation = {
    EN: 'There was a problem changing payment method',
    CZ: 'Při změně platební metody došlo k problému',
  };
  public static PASSWORDS_NOT_EQUAL: Translation = { EN: 'Passwords are not equal', CZ: 'Hesla nejsou stejná' };
  public static PASSWORDS_WRONG_OLD_PASSWORD: Translation = {
    EN: 'Current password is not correct',
    CZ: 'Aktuální heslo není správné',
  };
  public static MISSING_TRANSLATION: Translation = { EN: 'Missing translation', CZ: 'Chybí překlad' };
  public static MISSING_TYPE: Translation = { EN: 'Missing type', CZ: 'Chybí typ' };
  public static PERMISSION_VIOLATION: Translation = {
    EN: 'User does not have sufficient rights to access the requested data',
    CZ: 'Uživatel nemá dostatečná práva pro přístup k požadovaným údajům',
  };
  public static DYNAMIC_FORM_INACTIVE: Translation = {
    EN: 'There was a problem saving the form. Please refresh and try again',
    CZ: 'Při ukládání formuláře došlo k problému. Obnovte stránku a zkuste to znovu',
  };
  public static INVALID_PASSWORD_LENGTH: Translation = {
    EN: 'Password too short', CZ: 'Heslo je příliš krátké',
  };
  public static INVALID_PASSWORD_UPPER_CASE: Translation = {
    EN: 'Password doesn\'t contain any upper case letters', CZ: 'Heslo neobsahuje žádná velká písmena',
  };
  public static INVALID_PASSWORD_SPECIAL_CHARS: Translation = {
    EN: 'Password doesn\'t contain any special characters', CZ: 'Heslo neobsahuje žádné speciální znaky',
  };
  public static INVALID_PASSWORD_DIGITS: Translation = {
    EN: 'Password doesn\'t contain any numeric characters', CZ: 'Heslo neobsahuje žádné číselné znaky',
  };
  public static INVALID_PASSWORD_MAX_LENGTH: Translation = {
    EN: 'Password too long', CZ: 'Heslo je příliš dlouhé',
  };
  public static INVALID_PASSWORD_HISTORY: Translation = {
    EN: 'Password cannot be one of recently used passwords',
    CZ: 'Heslo nemůže být jedno z nedávno použitých hesel',
  };
  public static INVALID_PASSWORD_LOWER_CASE: Translation = {
    EN: 'Password doesn\'t contain any lower case letters', CZ: 'Heslo neobsahuje žádná malá písmena',
  };
  public static INVALID_PASSWORD_NOT_EMAIL: Translation = {
    EN: 'Password cannot be the same as your email address', CZ: 'Heslo nemůže být stejné jako vaše e-mailová adresa',
  };
  public static INVALID_PASSWORD: Translation = {
    EN: 'There was a problem validating your new password',
    CZ: 'Při ověřování vašeho nového hesla došlo k problému',
  };
  public static AVATAR_VALIDATION_FAILURE: Translation = {
    EN: 'Avatar image either too large or in wrong format!',
    CZ: 'Avatar je buď příliš velký, nebo ve špatném formátu',
  };
  public static AVATAR_UPLOAD_FAILURE: Translation = {
    EN: 'There was a problem uploading a new avatar image!',
    CZ: 'Při nahrávání nového avatara došlo k problému',
  };
}

export default ScautErrorHandlerLocale;
