import { Translation } from '../../core/localization/Localization.model';

class FormsLocale {
  public static TASKS: Translation = { EN: 'Forms', CZ: 'Formuláře' };
  public static CLIENT_NAME: Translation = { EN: 'Client', CZ: 'Klient' };
  public static STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static BUSINESS: Translation = { EN: 'Business', CZ: 'Podnikání a finance' };
  public static EMPLOYMENT: Translation = { EN: 'Employment', CZ: 'Zaměstnání' };
  public static PERSONAL_DATA: Translation = { EN: 'Personal data', CZ: 'Osobní údaje' };
  public static SCHOOL: Translation = { EN: 'Education', CZ: 'Vzdělání' };
  public static SOCIAL_MEDIA: Translation = { EN: 'Social media', CZ: 'Sociální média' };
  public static ADDITIONAL_INFO: Translation = { EN: 'Additional Info', CZ: 'Doplňující údaje' };
  public static POWER_OF_ATTORNEY: Translation = { EN: 'Power of Attorney', CZ: 'Plná moc' };
  public static NO_MANUAL_TASKS: Translation = {
    EN: 'There are no tasks waiting for you at the moment',
    CZ: 'V tuto chvíli na vás nečekají žádné formuláře',
  };
  public static COMPLETE_TASK_SUCCESS: Translation = {
    EN: 'Form completed succesfully',
    CZ: 'Formulář úspěšně dokončen',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default FormsLocale;
