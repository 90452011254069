import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@scaut-sro/npm-core-generator';
import { CandidateUserMapper } from '../dto/CandidateUser'
import {UseUpdateUserProfileData, UseUpdateUserProfileVariables} from './MutationServiceModel'
import { UserPreferencesMapper } from '../dto/UserPreferences'
import {UseChangeLanguageData, UseChangeLanguageVariables} from './MutationServiceModel'
import {UseChangePasswordData, UseChangePasswordVariables} from './MutationServiceModel'
import { DynamicFormValidationResultMapper } from '../dto/DynamicFormValidationResult'
import {UseFillFormData, UseFillFormVariables} from './MutationServiceModel'
import { FileDeleteHandlerResponseMapper } from '../dto/FileDeleteHandlerResponse'
import {UseDeleteFileData, UseDeleteFileVariables} from './MutationServiceModel'

export function useUpdateUserProfile(
  mapper: CandidateUserMapper,
  options?: MutationHookOptions<UseUpdateUserProfileData, UseUpdateUserProfileVariables>
): MutationTuple<UseUpdateUserProfileData, UseUpdateUserProfileVariables> {
  const mutationBuilder: GraphqlBuilder<CandidateUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateUserProfile')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateUser: 'CandidateUserGraphQLInput!',        
      })
      .setProperties(
        `candidateUser: $candidateUser`
      )
      .build(),
    options
  );
}


export function useChangeLanguage(
  mapper: UserPreferencesMapper,
  options?: MutationHookOptions<UseChangeLanguageData, UseChangeLanguageVariables>
): MutationTuple<UseChangeLanguageData, UseChangeLanguageVariables> {
  const mutationBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changeLanguage')
      .setReturnProperties(mapper)
      .declareVariables({
        language: 'Language!',        
      })
      .setProperties(
        `language: $language`
      )
      .build(),
    options
  );
}


export function useChangePassword(
  mapper: CandidateUserMapper,
  options?: MutationHookOptions<UseChangePasswordData, UseChangePasswordVariables>
): MutationTuple<UseChangePasswordData, UseChangePasswordVariables> {
  const mutationBuilder: GraphqlBuilder<CandidateUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changePassword')
      .setReturnProperties(mapper)
      .declareVariables({
        originalPassword: 'String!',
        password: 'String!',
        passwordConfirmation: 'String!',        
      })
      .setProperties(
        `originalPassword: $originalPassword,
        password: $password,
        passwordConfirmation: $passwordConfirmation`
      )
      .build(),
    options
  );
}


export function useFillForm(
  mapper: DynamicFormValidationResultMapper,
  options?: MutationHookOptions<UseFillFormData, UseFillFormVariables>
): MutationTuple<UseFillFormData, UseFillFormVariables> {
  const mutationBuilder: GraphqlBuilder<DynamicFormValidationResultMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('fillForm')
      .setReturnProperties(mapper)
      .declareVariables({
        formId: 'Long!',
        formData: 'MAP_String_Object!',        
      })
      .setProperties(
        `formId: $formId,
        formData: $formData`
      )
      .build(),
    options
  );
}


export function useDeleteFile(
  mapper: FileDeleteHandlerResponseMapper,
  options?: MutationHookOptions<UseDeleteFileData, UseDeleteFileVariables>
): MutationTuple<UseDeleteFileData, UseDeleteFileVariables> {
  const mutationBuilder: GraphqlBuilder<FileDeleteHandlerResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('deleteFile')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(
        `id: $id`
      )
      .build(),
    options
  );
}


