import { Translation } from '../../core/localization/Localization.model';

class PowerOfAttorneyStepLocale {
  public static INFO_TEXT1: Translation = {
    EN: 'An officially certified power of attorney signed by the inspected person is required for this inspection. '
        + 'This document can be downloaded after you or the candidate fill in all the required information. '
        + 'Before printing, please check whether the given information is entered correctly. '
        + 'If any of the information provided is incorrect, please contact our ',
    CZ: 'Pro tuto kontrolu je vyžadována úředně ověřená plná moc podepsaná kontrolovanou osobou. '
        + 'Tento dokument bude možné stáhnout po vyplnění všech požadovaných informací Vámi, nebo kandidátem. '
        + 'Před vytištěním prosíme zkontrolujte zda uvedené informace jsou zadány správně. '
        + 'V případě, že jakékoliv uvedené informace nejsou správné, prosíme kontaktujte ',
  };
  public static INFO_TEXT_SUPPORT: Translation = { EN: 'our support. ', CZ: 'naši podporu. ' };
  public static INFO_TEXT2: Translation = {
    EN: "Print the documents, have the candidate's signature officially verified, "
        + 'and then send the originals by post to the address',
    CZ: 'Dokumenty si vytiskněte, nechte úředně ověřit podpis kandidáta a následně pošlete poštou originály na adresu ',
  };

  public static INFO_TEXT3: Translation = {
    EN: 'or to',
    CZ: 'nebo do',
  };
  public static INFO_TEXT4: Translation = {
    EN: 'data box',
    CZ: 'datové schránky',
  };
}

export default PowerOfAttorneyStepLocale;
