import React, { useState } from 'react';
import {
  Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text,
} from '@chakra-ui/react';
import { Toast } from '@scaut-sro/meepo';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import Translate from '../../components/Translate/Translate';
import SupportLocale from './Support.locale';
import { ContactDto } from '../../build/generated-sources/dto/ContactDto';
import { useGetContact } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { SupportModalProps } from './SupportModalDetail.model';

const SupportModalDetail: React.FunctionComponent<SupportModalProps> = (props) => {
  const {
    isOpen, onClose,
  } = props;
  const [data, setData] = useState<ContactDto>();
  const { language } = useGetUser();

  useGetContact(
    {
      phone: true,
      email: true,
      hours: true,
    }, {
      onError: () => {
        Toast({
          title: translate(SupportLocale.ERROR_GETTING_CONTACT, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setData(response.contact);
      },
    },
  );

  return (
    <>
      <Modal closeOnOverlayClick={false} size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={SupportLocale.MODAL_SUPPORT_HEADER} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="1rem">
              <Translate label={SupportLocale.PHONE_TEXT} />
              {' '}
              (
              {data?.hours}
              )
            </Text>
            <Text fontWeight="bold" mb="2rem">
              <PhoneIcon mr="1rem" />
              {data?.phone}
            </Text>
            <Text mb="1rem">
              <Translate label={SupportLocale.EMAIL_TEXT} />
            </Text>
            <Text fontWeight="bold" mb="2rem">
              <EmailIcon mr="1rem" />
              <Button variant="link" onClick={() => window.open(`mailto:${data?.email}`)}>{data?.email}</Button>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SupportModalDetail;
