import { useGetUser } from '../store/reducers/UserSettingsReducer';
import { Translation } from './Localization.model';
import { Language } from '../../build/generated-sources/enum/Language';

export function translate(
  translation: Translation,
  language: Language,
): string {
  return translation[language];
}

export function useTranslation(translation: Translation) {
  const { language } = useGetUser();
  return translate(translation, language);
}
