import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import RootReducer from './reducers/RootReducer';

const store = configureStore({
  reducer: RootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, any, null, Action<string>>;

export default store;
