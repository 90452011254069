import React from 'react';
import { Center, Text } from '@chakra-ui/react';

const NoMatch: React.FunctionComponent = () => (
  <>
    <Center
      flexDirection="column"
    >
      <Text color="primaryBase" fontSize="7xl">404</Text>
      <Text>
        The page you requested is unavailable at the moment.
        Please press the back button or restart Scaut.
      </Text>
    </Center>
  </>
);
export default NoMatch;
