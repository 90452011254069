import React from 'react';
import { BsChevronRight } from 'react-icons/all';
import {
  Box, IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text, useDisclosure,
} from '@chakra-ui/react';
import ScautLogo from '../../components/Logo/Logo';

const FaqDetailModal: React.FunctionComponent<{ question: string, answer: string }> = ({ question, answer }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        aria-label="Open FAQ"
        onClick={onOpen}
        icon={<BsChevronRight />}
        variant="ghost"
        colorScheme="gray"
      />
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pb={5}>
          <Box height="100px" bg="#EEEFF3" display="flex" justifyContent="right">
            <Box paddingTop="40px" paddingRight="70px">
              <Box mt={-1}><ScautLogo /></Box>
            </Box>
          </Box>
          <ModalHeader>{question}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg" letterSpacing="200%" lineHeight="200%">{answer}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FaqDetailModal;
