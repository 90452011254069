import React from 'react';
import { Box } from '@chakra-ui/react';
import { OverviewCardProps } from './OverviewCard.model';

const OverviewCard: React.FunctionComponent<OverviewCardProps> = ({ bg, children }) => (
  <Box
    w="100%"
    h="176px"
    bg={bg}
    margin={0}
    border="1px"
    borderRadius="5px"
    borderColor="gray.200"
    display="flex"
    justifyContent="center"
    alignItems="center"
    p={8}
  >
    { children }
  </Box>
);

export default OverviewCard;
