import { GraphQLScalarType, Kind } from 'graphql';
import { Language } from '../generated-sources/enum/Language';

export interface MAP_LocalizationStrings{
  [Language.CZ]?: string;
  [Language.EN]?: string;
}

export const Resolver = new GraphQLScalarType({
  name: 'MAP_LocalizationStrings',
  description: 'MAP_LocalizationStrings custom scalar type',
  serialize(value) {
    return value && value.toString();
  },
  parseValue(value) {
    const map = new Map<string, string>();
    for (const val in value) {
      map.set(val, value[val]);
    }
    return {};
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
      return {};
    }
    return null;
  },
});
