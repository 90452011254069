import { Row } from '@scaut-sro/meepo';
import { CandidateFormDto } from '../../build/generated-sources/dto/CandidateFormDto';

export type FormsTableData = {
  clientName?: string;
  state?: any;
} & Row;

export type FormDetailProps = {
  candidateFormDto?: CandidateFormDto;
  onFormSend?: (orderId: number, values: any) => void;
  formIsLoading?: boolean;
};

export enum FormStage {
  INITIAL_FORM = 'INITIAL_FORM',
  ADDITIONAL_INFO = 'ADDITIONAL_INFO',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
}
