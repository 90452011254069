import { GraphQLScalarType, Kind } from 'graphql';

export type MAP_OrderOverviewType_Count = { [key: number | string]: any };

export const Resolver = new GraphQLScalarType({
  name: 'MAP_OrderOverviewType_Count',
  description: 'MAP_OrderOverviewType_Count custom scalar type',
  serialize(value) {
    return JSON.stringify(value);
  },
  parseValue(value) {
    const map = new Map<string, string>();
    for (const val in value) {
      map.set(val, value[val]);
    }
    return map;
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
      return {};
    }
    return null;
  },
});
