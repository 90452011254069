import React from 'react';
import { useTranslation } from '../../core/localization/LocaleUtils';
import { TranslateProps } from './Translate.model';

const Translate: React.FunctionComponent<TranslateProps> = (props) => {
  const { label } = props;
  const translation = useTranslation(label);
  return <>{translation}</>;
};

export default Translate;
