import React, { ChangeEvent } from 'react';
import { IoCloudUploadOutline } from 'react-icons/all';
import { Input } from '@chakra-ui/react';
import { AvatarProps } from '@chakra-ui/avatar/dist/types/avatar';
import { useKeycloak } from '@react-keycloak/web';
import { Tooltip } from '@scaut-sro/meepo';
import { AvatarInputStyle } from './AvatarInput.styles';
import { useGetUser, useUserSettingsReduce } from '../../core/store/reducers/UserSettingsReducer';
import { updateAvatar } from '../../core/service/queries/FileUploadService';
import { useTranslation } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import handleErrors from '../../core/error/ScautErrorHandler';

const AvatarInput: React.FunctionComponent<AvatarProps> = ({ ...props }) => {
  const filePickerRef = React.createRef<any>();
  const { setAvatar } = useUserSettingsReduce();
  const { keycloak: { token } } = useKeycloak();
  const { language } = useGetUser();

  const tooltipText = useTranslation(FormLocale.AVATAR_UPLOAD);

  const handleClick = () => {
    filePickerRef.current.click();
  };

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files;
    if (files) {
      updateAvatar(files[0], token as string)
        .then((response) => {
          setAvatar(response.data.responseBody as string);
        })
        .catch((error) => {
          const { exceptionDto } = error.response.data;
          handleErrors(
            exceptionDto.type,
            exceptionDto.message,
            exceptionDto.params,
            language,
          );
        });
    }
  };

  const onFileClicked = () => {
  };

  return (
    <>
      <Tooltip label={tooltipText}>
        <AvatarInputStyle {...props} onClick={handleClick}>
          <div className="overlay">
            <IoCloudUploadOutline />
          </div>
          <Input ref={filePickerRef} className="input-file" type="file" onChange={onFileChanged} onClick={onFileClicked} />
        </AvatarInputStyle>
      </Tooltip>
    </>
  );
};

export default AvatarInput;
