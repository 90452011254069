import React, { PropsWithChildren } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Translate, Translation } from '@scaut-sro/meepo';
import FullGreen from './fullGreen.png';
import HalfGreen from './halfGreen.png';
import HalfOrange from './halfOrange.png';
import OrangeEx from './orangeEx.png';
import {
  StateBadgeProps, StatesLocale,
} from './StateBadge.model';

function getStateBadgeData(state?: string, size?: string): {
  locale: Translation,
  icon: any,
} {
  const s = size === 'lg' ? '20px' : '16px';
  switch (state) {
    case 'WAITING_FOR_CANDIDATE': {
      return {
        locale: StatesLocale.WAITING_FOR_CANDIDATE,
        icon: <img src={HalfOrange} height={s} width={s} alt="Waiting for candidate" />,
      };
    }
    case 'WAITING_FOR_POA': {
      return {
        locale: StatesLocale.WAITING_FOR_POWER_OF_ATTORNEY,
        icon: <img src={HalfOrange} height={s} width={s} alt="Waiting for candidate" />,
      };
    }
    case 'FINISHED': {
      return {
        locale: StatesLocale.FINISHED,
        icon: <img src={FullGreen} height={s} width={s} alt="Finished" />,
      };
    }
    case 'CANNOT_COMPLETE': {
      return {
        locale: StatesLocale.CANNOT_COMPLETE,
        icon: <img src={OrangeEx} height={s} width={s} alt="Cannot complete" />,
      };
    }
    default: {
      return {
        locale: StatesLocale.PROCESSING,
        icon: <img src={HalfGreen} height={s} width={s} alt="Processing" />,
      };
    }
  }
}

const StateBadge = (
  props: PropsWithChildren<StateBadgeProps>,
) => {
  const { state, size } = props;
  const stateBadgeData = getStateBadgeData(state, size);

  return (
    <Flex alignItems="center">
      <>{stateBadgeData.icon}</>
      <Text ml={2} fontSize="sm"><Translate label={stateBadgeData.locale} /></Text>
    </Flex>
  );
};

export default StateBadge;
