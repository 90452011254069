import styled from '@emotion/styled';
import { Avatar } from '@chakra-ui/react';

// eslint-disable-next-line import/prefer-default-export
export const AvatarInputStyle = styled(Avatar)`
  height: 150px;
  width: 150px;
  cursor: pointer;
  background-color: #080808;

  .input-file {
    display: none;
  }

  img {
    transition: .2s ease;
  }
  
  .overlay {
    display: none;
    position: absolute;
    color: white;
    font-size: xx-large;
  }
  
  :hover {
    img {
      opacity: 0.6;
      transition: .2s ease;
    }
    
    .overlay {
      display: block;
    }
  }
`;
