import React from 'react';
import {
  H2, SectionContainer,
} from '@scaut-sro/meepo';
import { Badge } from '@chakra-ui/react';
import UserSettingsLocale from './UserSettings.locale';
import Translate from '../../components/Translate/Translate';
import UserInfoForm from './UserInfoForm';
import CommonLocale from '../../core/localization/translations/Common.locale';
import ChangePasswordForm from './ChangePasswordForm';
import AvatarSection from './AvatarSection';

const UserSettings: React.FunctionComponent = () => (
  <>
    <AvatarSection />
    <SectionContainer>
      <H2 mb={6}><Translate label={UserSettingsLocale.PERSONAL_INFORMATION} /></H2>
      <UserInfoForm />
    </SectionContainer>
    <SectionContainer>
      <H2 mb={6}>
        <Translate label={UserSettingsLocale.PASSWORD_CHANGE} />
        <Badge ml="3" colorScheme="red" fontSize="sm">
          <Translate label={CommonLocale.DANGER_ZONE} />
        </Badge>
      </H2>
      <ChangePasswordForm />
    </SectionContainer>
  </>
);

export default UserSettings;
