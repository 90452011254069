import React from 'react';
import { Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { RouteProps } from 'react-router';
import NoMatch from '../../views/404/404';
import ScautCandidateRoles from '../../core/auth/ScautCandidateRoles';

interface PrivateRouteProps extends RouteProps{
  requiredRoles: ScautCandidateRoles[]
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = (
  { children, requiredRoles, ...route },
) => {
  const { keycloak } = useKeycloak();

  let isAuthorized = true;

  requiredRoles.forEach((role) => {
    if (!keycloak.hasResourceRole(role, 'scaut-candidate-app')) {
      isAuthorized = false;
    }
  });

  return (
    <>
      {
          isAuthorized ? (
            <Route {...route}>
              {children}
            </Route>
          ) : (<NoMatch />)
        }
    </>
  );
};

export default PrivateRoute;
