import React, { useEffect } from 'react';
import {
  Center, FormErrorMessage,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { PatternedContainer } from '@scaut-sro/meepo';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import AvatarInput from '../../components/AvatarInput/AvatarInput';

const AvatarSection: React.FunctionComponent = () => {
  const { userProfile } = useGetUser();
  const { formState: { errors }, reset } = useForm();

  useEffect(() => {
    reset(userProfile);
  }, [reset, userProfile]);
  return (
    <>
      <PatternedContainer image="/pattern.svg">
        <Center m={6}>
          <AvatarInput src={userProfile?.avatar} />
          <FormErrorMessage>
            {errors.avatar && errors.avatar.message}
          </FormErrorMessage>
        </Center>
      </PatternedContainer>
    </>
  );
};

export default AvatarSection;
