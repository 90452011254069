import { ApolloError } from '@apollo/client';
import { UseToastOptions } from '@chakra-ui/react';
import { Toast } from '@scaut-sro/meepo';
import { format } from 'react-string-format';
import { translate } from '../localization/LocaleUtils';
import FormLocale from '../localization/translations/Form.locale';
import { Language } from '../../build/generated-sources/enum/Language';
import ScautError from './ScautErrorHandler.model';
import ScautErrorHandlerLocale from './ScautErrorHandler.locale';

export default function handleErrors(message: string,
  exceptionMessage: string,
  params: string[],
  language: Language,
  options?: UseToastOptions) {
  let errorMessage = message;
  if (message && message in ScautError) {
    const scautError = translate(ScautErrorHandlerLocale[message as ScautError], language);
    if (params && params.length > 0) {
      errorMessage = format(scautError, params[0]);
    } else {
      errorMessage = scautError;
    }
  }
  Toast({
    title: translate(FormLocale.ERROR, language),
    description: errorMessage || exceptionMessage,
    status: 'error',
    position: 'bottom-right',
    variant: 'solid',
    isClosable: true,
    ...options,
  });
}

export function handleApolloErrors(error: ApolloError,
  language: Language,
  options?: UseToastOptions) {
  handleErrors(error.message,
    error.graphQLErrors[0]?.extensions?.exceptionMessage as string,
    error.graphQLErrors[0]?.extensions?.params,
    language,
    options);
}
