import { DynamicFormField, parseOrDefault } from '@scaut-sro/meepo';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { FormFieldDto } from '../../build/generated-sources/dto/FormFieldDto';
import { Language } from '../../build/generated-sources/enum/Language';
import { MAP_PreloadedTranslations } from '../../build/scalars/MAP_PreloadedTranslations';

const resolveStringTranslation = (preloadedTranslations: MAP_PreloadedTranslations, language: Language, label?: string) => {
  if (!label) {
    return '';
  }
  return preloadedTranslations[label]?.localizationStrings?.[language] || label || '';
};

const resolveOptionsTranslation = (
  preloadedTranslations: MAP_PreloadedTranslations,
  language: Language,
  options?: RegisterOptions<FieldValues, string>,
) => {
  if (!options) {
    return undefined;
  }
  if (preloadedTranslations[options.required as string]) {
    return {
      ...options,
      required: preloadedTranslations[options.required as string]?.localizationStrings?.[language]
          || options.required
          || '',
    };
  }
  return options;
};

const resolveSelectOptionsTranslation = (preloadedTranslations: MAP_PreloadedTranslations, language: Language, selectOptions?: {
  value: string | number;
  label: string;
}[]) => {
  if (!selectOptions) {
    return undefined;
  }

  return selectOptions.map((selectOption) => ({
    label: resolveStringTranslation(preloadedTranslations, language, selectOption.label),
    value: selectOption.value,
  }));
};

const MapFormFieldDtoTODynamicFormField = (definition: FormFieldDto, language: Language): DynamicFormField => {
  const { properties, preloadedTranslations } = definition;

  const comparisonFormField: FormFieldDto[] = parseOrDefault(properties?.screeningComparisonTableFields as any as string, []);
  const fixedComparisonFormFields: DynamicFormField[] = [];

  if (comparisonFormField) {
    comparisonFormField.forEach((field) => {
      fixedComparisonFormFields.push(MapFormFieldDtoTODynamicFormField(field, language));
    });
  }

  return {
    ...properties,
    showConditionally: parseOrDefault(properties?.showConditionally as any as string, undefined),
    enableConditionally: parseOrDefault(properties?.enableConditionally as any as string, undefined),
    selectRDPrefill: parseOrDefault(properties?.selectRDPrefill as any as string, []),
    selectOptions: resolveSelectOptionsTranslation(preloadedTranslations as MAP_PreloadedTranslations, language,
      parseOrDefault(properties?.selectOptions as any as string, [])),
    executionResults: parseOrDefault(properties?.executionResults as any as string, undefined),
    executionDetailResults: parseOrDefault(properties?.executionDetailResults as any as string, undefined),
    screeningComparisonTableFields: fixedComparisonFormFields || [],
    fileUploadOptions: parseOrDefault(properties?.fileUploadOptions as any as string, undefined),
    countableDynamicFieldGridProps: parseOrDefault(properties?.countableDynamicFieldGridProps as any as string, undefined),
    countableDynamicFields: parseOrDefault(properties?.countableDynamicFields as any as string, undefined),
    colspan: parseOrDefault(properties?.colspan as any as string, []),
    options: resolveOptionsTranslation(preloadedTranslations as MAP_PreloadedTranslations, language,
      parseOrDefault(properties?.options as any as string, undefined)),
    readonlyTable: parseOrDefault(properties?.readonlyTable as any as string, undefined),
    keyValueDetail: parseOrDefault(properties?.keyValueDetail as any as string, undefined),
    id: definition.id,
    label: resolveStringTranslation(preloadedTranslations as MAP_PreloadedTranslations, language, definition.label),
    tooltip: resolveStringTranslation(preloadedTranslations as MAP_PreloadedTranslations, language, properties?.tooltip),
    value: definition.value,
    defaultValue: definition.defaultValue,
  } as DynamicFormField;
};

export default MapFormFieldDtoTODynamicFormField;
