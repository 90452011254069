import React, { useState } from 'react';
import {
  Box, Button, Center, Flex, GridItem, Heading, SimpleGrid, Spacer, Text,
} from '@chakra-ui/react';
import { SectionContainer, Toast } from '@scaut-sro/meepo';
import '../../../public/scaut-logo.svg';
import { useHistory } from 'react-router-dom';
import FaqDetailModal from './FaqDetailModal';
import { useGetFaqs, useGetGetFormsOverview } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import FormsLocale from '../Forms/Forms.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { FormDetailProps } from '../Forms/Forms.model';
import Translate from '../../components/Translate/Translate';
import { CandidateOverviewDto } from '../../build/generated-sources/dto/CandidateOverviewDto';
import OverviewLocale from './Overview.locale';
import OverviewCard from '../../components/OverviewCard/OverviewCard';
import { FAQ } from '../../build/generated-sources/dto/FAQ';
import FaqLocale from '../Faq/Faq.locale';
import StateBadge from '../../components/StateBadge/StateBadge';

const Overview: React.FunctionComponent<FormDetailProps> = () => {
  const [list, setList] = useState<CandidateOverviewDto[]>();
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const { language } = useGetUser();
  const history = useHistory();

  useGetFaqs(
    {
      id: true,
      question: { localizationStrings: true },
      answer: { localizationStrings: true },
    }, {
      onError: () => {
        Toast({
          title: translate(FaqLocale.ERROR_GETTING_FAQ, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setFaqList(response.faqs);
      },
    },
  );

  useGetGetFormsOverview(
    {
      orderId: true,
      state: true,
      client: {
        name: true,
      },
      jobPosition: true,
    },
    {
      onCompleted: (response) => {
        setList(response.getFormsOverview);
      },
      onError: () => {
        Toast({
          title: translate(FormsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'network-only',
    },
  );

  const openFormDetail = (formDetailId: number | undefined) => {
    if (formDetailId) {
      history.push(`/form/${formDetailId}`);
    } else {
      history.push('/404');
    }
  };

  return (
    <SectionContainer>
      <Box height="214px" background="#EEEFF3" display="flex" justifyContent="right">
        <img
          alt="Scaut header background"
          src={`${process.env.PUBLIC_URL}/candidateHeaderBackground.png`}
          width="100%"
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box paddingTop="30px">
        <Heading size="md" mb="20px"><Translate label={OverviewLocale.WELCOME_TEXT_HEADER} /></Heading>
        <Text fontSize="lg" lineHeight="200%">
          <Translate label={OverviewLocale.WELCOME_TEXT} />
        </Text>
      </Box>
      <Box paddingTop="30px">
        <Heading size="lg" mb="20px"><Translate label={OverviewLocale.YOUR_OPEN_QUESTIONNAIRES_TEXT} /></Heading>
        {list?.length ? (
          <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
            {
              list.map((form) => (
                <GridItem>
                  <OverviewCard bg="#EEEFF3">
                    <Flex w="100%" h="100%" flexDirection="column">
                      <Box>
                        <Text fontWeight="bold" fontSize="2xl">
                          {form?.jobPosition}
                        </Text>
                        <Text fontSize="md">{form?.client?.name}</Text>
                      </Box>
                      <Spacer />
                      <Flex>
                        <StateBadge state={form.state} size="lg" />
                        <Spacer />
                        {
                          form.state === 'WAITING_FOR_CANDIDATE' || form.state === 'WAITING_FOR_POA'
                            ? (
                              <Button onClick={() => openFormDetail(form?.orderId)}>
                                <Translate label={OverviewLocale.ADD_INFORMATION} />
                              </Button>
                            )
                            : (
                              <Box>
                                <Text fontSize="sm" textAlign="right">
                                  <Translate label={OverviewLocale.THANK_YOU_FOR_FILLING_THE_FORM_1} />
                                </Text>
                                <Text fontSize="sm" textAlign="right">
                                  <Translate label={OverviewLocale.THANK_YOU_FOR_FILLING_THE_FORM_2} />
                                </Text>
                              </Box>
                            )
                        }
                      </Flex>
                    </Flex>
                  </OverviewCard>
                </GridItem>
              ))
            }
          </SimpleGrid>
        ) : (
          <Center
            border="1px"
            borderRadius="5px"
            borderColor="gray.200"
            h="176px"
          >
            <Text fontWeight={600}>
              <Translate label={OverviewLocale.NO_FORM} />
            </Text>
          </Center>
        )}

      </Box>
      <Box paddingTop="30px">
        <Heading size="md" mb="20px">FAQ</Heading>
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          { faqList.map((faqItem) => (
            <GridItem key={faqItem.id}>
              <OverviewCard>
                <Flex w="100%">
                  <Box lineHeight="200%">
                    <Text fontWeight="bold" fontSize="2xl">{faqItem.question?.localizationStrings?.[language]}</Text>
                    <Text noOfLines={2} fontSize="lg">{faqItem.answer?.localizationStrings?.[language]}</Text>
                  </Box>
                  <Spacer />
                  <Flex alignItems="center" justifyContent="flex-end" flexDirection="column">
                    <FaqDetailModal
                      question={faqItem.question?.localizationStrings?.[language] || ''}
                      answer={faqItem.answer?.localizationStrings?.[language] || ''}
                    />
                  </Flex>
                </Flex>
              </OverviewCard>
            </GridItem>
          )) }
        </SimpleGrid>
      </Box>
    </SectionContainer>
  );
};
export default Overview;
