import {
  QueryHookOptions,
  useQuery,
  useLazyQuery,
  QueryResult,
  QueryTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@scaut-sro/npm-core-generator';
import { CandidateUserMapper } from '../dto/CandidateUser'
import {UseGetUserProfileData, UseGetUserProfileVariables} from './QueryServiceModel'
import { UserPreferencesMapper } from '../dto/UserPreferences'
import {UseGetUserPreferencesData, UseGetUserPreferencesVariables} from './QueryServiceModel'
import { KeycloakPasswordPolicyDtoMapper } from '../dto/KeycloakPasswordPolicyDto'
import {UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables} from './QueryServiceModel'
import { CandidateFormDtoMapper } from '../dto/CandidateFormDto'
import {UseGetGetFormsData, UseGetGetFormsVariables} from './QueryServiceModel'
import { CandidateOverviewDtoMapper } from '../dto/CandidateOverviewDto'
import {UseGetGetFormsOverviewData, UseGetGetFormsOverviewVariables} from './QueryServiceModel'
import {UseGetGetFormData, UseGetGetFormVariables} from './QueryServiceModel'
import { EnumerationMapper } from '../dto/Enumeration'
import {UseGetEnumerationsData, UseGetEnumerationsVariables} from './QueryServiceModel'
import { ResourceDatabaseItemMapper } from '../dto/ResourceDatabaseItem'
import {UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables} from './QueryServiceModel'
import {UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables} from './QueryServiceModel'
import { ContactDtoMapper } from '../dto/ContactDto'
import {UseGetContactData, UseGetContactVariables} from './QueryServiceModel'
import { FAQMapper } from '../dto/FAQ'
import {UseGetFaqsData, UseGetFaqsVariables} from './QueryServiceModel'
import { FileInfoMapper } from '../dto/FileInfo'
import {UseGetGetFilesInfoData, UseGetGetFilesInfoVariables} from './QueryServiceModel'
import {UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables} from './QueryServiceModel'

export function useGetUserProfile(
  mapper: CandidateUserMapper,
  options?: QueryHookOptions<UseGetUserProfileData, UseGetUserProfileVariables>
): QueryResult<UseGetUserProfileData, UseGetUserProfileVariables> {
  const queryBuilder: GraphqlBuilder<CandidateUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('userProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetUserProfile(
  mapper: CandidateUserMapper,
  options?: QueryHookOptions<UseGetUserProfileData, UseGetUserProfileVariables>
): QueryTuple<UseGetUserProfileData, UseGetUserProfileVariables> {
  const queryBuilder: GraphqlBuilder<CandidateUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('userProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetUserPreferences(
  mapper: UserPreferencesMapper,
  options?: QueryHookOptions<UseGetUserPreferencesData, UseGetUserPreferencesVariables>
): QueryResult<UseGetUserPreferencesData, UseGetUserPreferencesVariables> {
  const queryBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('userPreferences')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetUserPreferences(
  mapper: UserPreferencesMapper,
  options?: QueryHookOptions<UseGetUserPreferencesData, UseGetUserPreferencesVariables>
): QueryTuple<UseGetUserPreferencesData, UseGetUserPreferencesVariables> {
  const queryBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('userPreferences')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPasswordPolicies(
  mapper: KeycloakPasswordPolicyDtoMapper,
  options?: QueryHookOptions<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables>
): QueryResult<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakPasswordPolicyDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('passwordPolicies')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetPasswordPolicies(
  mapper: KeycloakPasswordPolicyDtoMapper,
  options?: QueryHookOptions<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables>
): QueryTuple<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakPasswordPolicyDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('passwordPolicies')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetGetForms(
  mapper: CandidateFormDtoMapper,
  options?: QueryHookOptions<UseGetGetFormsData, UseGetGetFormsVariables>
): QueryResult<UseGetGetFormsData, UseGetGetFormsVariables> {
  const queryBuilder: GraphqlBuilder<CandidateFormDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getForms')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetGetForms(
  mapper: CandidateFormDtoMapper,
  options?: QueryHookOptions<UseGetGetFormsData, UseGetGetFormsVariables>
): QueryTuple<UseGetGetFormsData, UseGetGetFormsVariables> {
  const queryBuilder: GraphqlBuilder<CandidateFormDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getForms')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetGetFormsOverview(
  mapper: CandidateOverviewDtoMapper,
  options?: QueryHookOptions<UseGetGetFormsOverviewData, UseGetGetFormsOverviewVariables>
): QueryResult<UseGetGetFormsOverviewData, UseGetGetFormsOverviewVariables> {
  const queryBuilder: GraphqlBuilder<CandidateOverviewDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getFormsOverview')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetGetFormsOverview(
  mapper: CandidateOverviewDtoMapper,
  options?: QueryHookOptions<UseGetGetFormsOverviewData, UseGetGetFormsOverviewVariables>
): QueryTuple<UseGetGetFormsOverviewData, UseGetGetFormsOverviewVariables> {
  const queryBuilder: GraphqlBuilder<CandidateOverviewDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getFormsOverview')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetGetForm(
  mapper: CandidateFormDtoMapper,
  options?: QueryHookOptions<UseGetGetFormData, UseGetGetFormVariables>
): QueryResult<UseGetGetFormData, UseGetGetFormVariables> {
  const queryBuilder: GraphqlBuilder<CandidateFormDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getForm')
      .setReturnProperties(mapper)
      .declareVariables({
        orderId: 'Long!',        
      })
      .setProperties(`orderId: $orderId`)
      .build(),
    options
  );
}

export function useLazyGetGetForm(
  mapper: CandidateFormDtoMapper,
  options?: QueryHookOptions<UseGetGetFormData, UseGetGetFormVariables>
): QueryTuple<UseGetGetFormData, UseGetGetFormVariables> {
  const queryBuilder: GraphqlBuilder<CandidateFormDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getForm')
      .setReturnProperties(mapper)
      .declareVariables({
        orderId: 'Long!',        
      })
      .setProperties(`orderId: $orderId`)
      .build(),
    options
  );
}


export function useGetEnumerations(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsData, UseGetEnumerationsVariables>
): QueryResult<UseGetEnumerationsData, UseGetEnumerationsVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('enumerations')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationType: 'EnumerationType!',        
      })
      .setProperties(`enumerationType: $enumerationType`)
      .build(),
    options
  );
}

export function useLazyGetEnumerations(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsData, UseGetEnumerationsVariables>
): QueryTuple<UseGetEnumerationsData, UseGetEnumerationsVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('enumerations')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationType: 'EnumerationType!',        
      })
      .setProperties(`enumerationType: $enumerationType`)
      .build(),
    options
  );
}


export function useGetResourceDatabaseItems(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>
): QueryResult<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('resourceDatabaseItems')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionName: 'String!',
        countryId: 'Long',
        search: 'String',        
      })
      .setProperties(`sectionName: $sectionName, countryId: $countryId, search: $search`)
      .build(),
    options
  );
}

export function useLazyGetResourceDatabaseItems(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>
): QueryTuple<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('resourceDatabaseItems')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionName: 'String!',
        countryId: 'Long',
        search: 'String',        
      })
      .setProperties(`sectionName: $sectionName, countryId: $countryId, search: $search`)
      .build(),
    options
  );
}


export function useGetGetResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>
): QueryResult<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getResourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}

export function useLazyGetGetResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>
): QueryTuple<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getResourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}


export function useGetContact(
  mapper: ContactDtoMapper,
  options?: QueryHookOptions<UseGetContactData, UseGetContactVariables>
): QueryResult<UseGetContactData, UseGetContactVariables> {
  const queryBuilder: GraphqlBuilder<ContactDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('contact')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetContact(
  mapper: ContactDtoMapper,
  options?: QueryHookOptions<UseGetContactData, UseGetContactVariables>
): QueryTuple<UseGetContactData, UseGetContactVariables> {
  const queryBuilder: GraphqlBuilder<ContactDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('contact')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetFaqs(
  mapper: FAQMapper,
  options?: QueryHookOptions<UseGetFaqsData, UseGetFaqsVariables>
): QueryResult<UseGetFaqsData, UseGetFaqsVariables> {
  const queryBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('faqs')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetFaqs(
  mapper: FAQMapper,
  options?: QueryHookOptions<UseGetFaqsData, UseGetFaqsVariables>
): QueryTuple<UseGetFaqsData, UseGetFaqsVariables> {
  const queryBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('faqs')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetGetFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>
): QueryResult<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}

export function useLazyGetGetFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>
): QueryTuple<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}


export function useGetGetUploadedFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>
): QueryResult<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getUploadedFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        fileUploadId: 'String!',        
      })
      .setProperties(`fileUploadId: $fileUploadId`)
      .build(),
    options
  );
}

export function useLazyGetGetUploadedFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>
): QueryTuple<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getUploadedFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        fileUploadId: 'String!',        
      })
      .setProperties(`fileUploadId: $fileUploadId`)
      .build(),
    options
  );
}


