import React from 'react';
import { SimpleDialog } from '@scaut-sro/meepo';
import { ScautDialogProps } from './ScautDialog.model';
import Translate from '../Translate/Translate';
import ScautDialogLocale from '../../core/localization/translations/ScautDialog.locale';

const ScautDialog: React.FunctionComponent<ScautDialogProps> = (props) => {
  const {
    onClose, onConfirm, isOpen, cancelRef, header, confirmButtonLabel, cancelButtonLabel, ...rest
  } = props;
  return (
    <SimpleDialog
      onClose={onClose}
      onConfirm={onConfirm}
      isOpen={isOpen}
      cancelRef={cancelRef}
      header={header || <Translate label={ScautDialogLocale.HEADER} />}
      confirmButtonLabel={confirmButtonLabel || <Translate label={ScautDialogLocale.CONFIRM} />}
      cancelButtonLabel={cancelButtonLabel || <Translate label={ScautDialogLocale.CANCEL} />}
      {...rest}
    />
  );
};

export default ScautDialog;
