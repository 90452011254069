import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { GraphqlBuilder, GraphQLType } from '@scaut-sro/npm-core-generator';
import {
  RDCountry, RDItem, RDSelectFilter, SelectRDApi,
} from '@scaut-sro/meepo/lib/components/SelectRD/SelectRD.model';
import { Translation } from '@scaut-sro/meepo';
import { ResourceDatabaseItemMapper } from '../../../build/generated-sources/dto/ResourceDatabaseItem';
import {
  UseGetEnumerationsData,
  UseGetEnumerationsVariables,
  UseGetGetResourceDatabaseItemData,
  UseGetGetResourceDatabaseItemVariables,
  UseGetResourceDatabaseItemsData,
  UseGetResourceDatabaseItemsVariables,
} from '../../../build/generated-sources/service/QueryServiceModel';
import { EnumerationMapper } from '../../../build/generated-sources/dto/Enumeration';
import { EnumerationType } from '../../../build/generated-sources/enum/EnumerationType';

export function getEnumerations(
  client: ApolloClient<any>,
  mapper: EnumerationMapper,
  variables: UseGetEnumerationsVariables,
): Promise<ApolloQueryResult<UseGetEnumerationsData>> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('enumerations')
    .setReturnProperties(mapper)
    .declareVariables({
      enumerationType: 'EnumerationType!',
    })
    .setProperties('enumerationType: $enumerationType')
    .build();

  return client.query<UseGetEnumerationsData, UseGetEnumerationsVariables>({
    query,
    variables,
  });
}

export function getResourceDatabaseItems(
  client: ApolloClient<any>,
  mapper: ResourceDatabaseItemMapper,
  variables: UseGetResourceDatabaseItemsVariables,
): Promise<ApolloQueryResult<UseGetResourceDatabaseItemsData>> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('resourceDatabaseItems')
    .setReturnProperties(mapper)
    .declareVariables({
      sectionName: 'String!',
      countryId: 'Long',
      search: 'String',
    })
    .setProperties('sectionName: $sectionName, countryId: $countryId, search: $search')
    .build();

  return client.query<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>({
    query,
    variables,
  });
}

export function getResourceDatabaseItem(
  client: ApolloClient<any>,
  mapper: ResourceDatabaseItemMapper,
  variables: UseGetGetResourceDatabaseItemVariables,
): Promise<ApolloQueryResult<UseGetGetResourceDatabaseItemData>> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('getResourceDatabaseItem')
    .setReturnProperties(mapper)
    .declareVariables({
      id: 'Long!',
    })
    .setProperties('id: $id')
    .build();

  return client.query<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>({
    query,
    variables,
  });
}

export function getSelectRDApi(client: ApolloClient<any>): SelectRDApi {
  return {
    getData: (filter: RDSelectFilter, callback: (items: RDItem[]) => void) => {
      getResourceDatabaseItems(client, {
        id: true,
        name: true,
        enumeration: {
          id: true,
          translation: {
            localizationStrings: true,
          },
        },
        resourceDatabaseFields: {
          id: true,
          value: true,
          resourceDatabaseFieldDefinition: {
            id: true,
            fieldName: true,
            formInputType: true,
            isMandatory: true,
          },
        },
      }, {
        sectionName: filter.section,
        search: filter.fullTextSearch,
        countryId: filter.countryId,
      }).then((data) => {
        if (data.data.resourceDatabaseItems) {
          const items = data.data.resourceDatabaseItems;
          const rdItems: RDItem[] = items.map((item) => ({
            id: item.id as number,
            name: item.name as string,
            country: {
              id: item.enumeration?.id as number,
              name: item.enumeration?.translation?.localizationStrings as Translation,
            },
            fields: item.resourceDatabaseFields?.map((field) => ({
              name: field.resourceDatabaseFieldDefinition?.fieldName as string,
              value: field.value as string,
              type: JSON.parse(JSON.stringify(field.resourceDatabaseFieldDefinition?.formInputType)),
            })) || [],
          }));
          callback(rdItems);
        }
      });
    },
    getAvailableCountries: (callback: (countries: RDCountry[]) => void) => {
      getEnumerations(client, {
        id: true,
        translation: {
          localizationStrings: true,
        },
      }, {
        enumerationType: EnumerationType.COUNTRY,
      }).then((data) => {
        callback(data.data.enumerations.map((enumeration) => ({
          name: enumeration.translation?.localizationStrings as Translation,
          id: enumeration.id as number,
        })));
      });
    },
    getSelectedValue: (id: number, callback: (item?: RDItem) => void) => {
      getResourceDatabaseItem(client, {
        id: true,
        name: true,
        resourceDatabaseFields: {
          id: true,
          value: true,
          resourceDatabaseFieldDefinition: {
            id: true,
            fieldName: true,
            formInputType: true,
            isMandatory: true,
          },
        },
      }, {
        id,
      }).then((data) => {
        if (data.data.getResourceDatabaseItem) {
          const item = data.data.getResourceDatabaseItem;
          const rdItem = {
            id: item.id as number,
            name: item.name as string,
            country: {
              id: item.enumeration?.id as number,
              name: item.enumeration?.translation?.localizationStrings as Translation,
            },
            fields: item.resourceDatabaseFields?.map((field) => ({
              name: field.resourceDatabaseFieldDefinition?.fieldName as string,
              value: field.value as string,
              type: JSON.parse(JSON.stringify(field.resourceDatabaseFieldDefinition?.formInputType)),
            })) || [],
          };
          callback(rdItem);
        }
      });
    },
  };
}
