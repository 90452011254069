import { Resolver as LongResolver } from  "@scaut-sro/npm-core-generator/dist/src/type/Long" ;
import { Resolver as LocalDateResolver } from  "../../scalars/LocalDate" ;
import { Resolver as LocalDateTimeResolver } from  "../../scalars/LocalDateTime" ;
import { Resolver as MAP_LocalizationStringsResolver } from  "../../scalars/MAP_LocalizationStrings" ;
import { Resolver as MAP_FormDataResolver } from  "../../scalars/MAP_FormData" ;
import { Resolver as MAP_FieldId_FormFieldDtoResolver } from  "../../scalars/MAP_FieldId_FormFieldDto" ;
import { Resolver as MAP_ScreeningId_DynamicFormResolver } from  "../../scalars/MAP_ScreeningId_DynamicForm" ;
import { Resolver as MAP_ComponentId_DynamicFormResolver } from  "../../scalars/MAP_ComponentId_DynamicForm" ;
import { Resolver as MAP_ComponentId_CandidateScreeningComponentDataResolver } from  "../../scalars/MAP_ComponentId_CandidateScreeningComponentData" ;
import { Resolver as MAP_FileId_FileDtoResolver } from  "../../scalars/MAP_FileId_FileDto" ;
import { Resolver as MAP_String_StringResolver } from  "../../scalars/MAP_String_String" ;
import { Resolver as MAP_String_ObjectResolver } from  "../../scalars/MAP_String_Object" ;
import { Resolver as MAP_OrderOverviewType_CountResolver } from  "../../scalars/MAP_OrderOverviewType_Count" ;
import { Resolver as MAP_DynamicFieldPropertiesResolver } from  "../../scalars/MAP_DynamicFieldProperties" ;
import { Resolver as MAP_PreloadedTranslationsResolver } from  "../../scalars/MAP_PreloadedTranslations" ;
import { Resolver as AnyResolver } from  "../../scalars/Any" ;
import { gql } from "@apollo/client" ;
import { makeExecutableSchema } from "@graphql-tools/schema" ;


const resolvers = {
  Long: LongResolver,  LocalDate: LocalDateResolver,  LocalDateTime: LocalDateTimeResolver,  MAP_LocalizationStrings: MAP_LocalizationStringsResolver,  MAP_FormData: MAP_FormDataResolver,  MAP_FieldId_FormFieldDto: MAP_FieldId_FormFieldDtoResolver,  MAP_ScreeningId_DynamicForm: MAP_ScreeningId_DynamicFormResolver,  MAP_ComponentId_DynamicForm: MAP_ComponentId_DynamicFormResolver,  MAP_ComponentId_CandidateScreeningComponentData: MAP_ComponentId_CandidateScreeningComponentDataResolver,  MAP_FileId_FileDto: MAP_FileId_FileDtoResolver,  MAP_String_String: MAP_String_StringResolver,  MAP_String_Object: MAP_String_ObjectResolver,  MAP_OrderOverviewType_Count: MAP_OrderOverviewType_CountResolver,  MAP_DynamicFieldProperties: MAP_DynamicFieldPropertiesResolver,  MAP_PreloadedTranslations: MAP_PreloadedTranslationsResolver,  Any: AnyResolver};

const typeDefs = gql`
  scalar Long  scalar LocalDate  scalar LocalDateTime  scalar MAP_LocalizationStrings  scalar MAP_FormData  scalar MAP_FieldId_FormFieldDto  scalar MAP_ScreeningId_DynamicForm  scalar MAP_ComponentId_DynamicForm  scalar MAP_ComponentId_CandidateScreeningComponentData  scalar MAP_FileId_FileDto  scalar MAP_String_String  scalar MAP_String_Object  scalar MAP_OrderOverviewType_Count  scalar MAP_DynamicFieldProperties  scalar MAP_PreloadedTranslations  scalar Any`;

export const schema = makeExecutableSchema({
    typeDefs,
    resolvers,
  });
