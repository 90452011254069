import React, { useMemo, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  useDisclosure, AccordionIcon,
} from '@chakra-ui/react';
import { LeftMenuItem, Toast } from '@scaut-sro/meepo';
import { FaRegQuestionCircle } from 'react-icons/all';
import Translate from '../../components/Translate/Translate';
import MainMenuLocale from '../../core/localization/translations/MainMenu.locale';
import { MainMenuProps } from '../../components/MainMenu/MainMenu.model';
import FaqLocale from './Faq.locale';
import { useGetFaqs } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { FAQ } from '../../build/generated-sources/dto/FAQ';

const FaqModal: React.FunctionComponent<MainMenuProps> = (props) => {
  const {
    leftMenuWidth, headerHeight, activeItem,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const { language } = useGetUser();

  useGetFaqs(
    {
      id: true,
      question: { localizationStrings: true },
      answer: { localizationStrings: true },
    }, {
      onError: () => {
        Toast({
          title: translate(FaqLocale.ERROR_GETTING_FAQ, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setFaqList(response.faqs);
      },
    },
  );

  const faqOptions = useMemo(() => faqList.map((f) => ({
    value: f.id,
    question: f?.question?.localizationStrings?.[language],
    answer: f?.answer?.localizationStrings?.[language],
  })), [faqList, language]);

  return (
    <>
      <Button variant="link" onClick={onOpen}>
        <LeftMenuItem
          activeItem={activeItem}
          itemId="faq"
          headerHeight={headerHeight}
          leftMenuWidth={leftMenuWidth}
          icon={<FaRegQuestionCircle />}
        >
          <Translate label={MainMenuLocale.FAQ} />
        </LeftMenuItem>
      </Button>
      <Modal closeOnOverlayClick={false} size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={FaqLocale.MODAL_FAQ_HEADER} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" p="4">
              <Accordion allowToggle allowMultiple>
                {
                faqOptions.map((faq) => (
                  <AccordionItem key={faq.value}>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="semibold">
                          {faq.question}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb="4">
                      {faq.answer}
                    </AccordionPanel>
                  </AccordionItem>
                ))
            }
              </Accordion>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FaqModal;
