import { Translation } from '../../core/localization/Localization.model';

class UserSettingsLocale {
  public static USER_SETTINGS: Translation = { EN: 'User settings', CZ: 'Uživatelské nastavení' };
  public static PERSONAL_INFORMATION: Translation = { EN: 'Personal information', CZ: 'Osobní údaje' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static FIRST_NAME_PLACEHOLDER: Translation = { EN: 'John', CZ: 'Jan' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static LAST_NAME_PLACEHOLDER: Translation = { EN: 'Doe', CZ: 'Novák' };
  public static POSITION: Translation = { EN: 'Position', CZ: 'Pozice' };
  public static POSITION_PLACEHOLDER: Translation = { EN: 'Manager', CZ: 'Manažer' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static EMAIL_PLACEHOLDER: Translation = { EN: 'john.doe@mycompany.com', CZ: 'jan.novak@mojefirma.cz' };
  public static EMAIL_ERROR_INVALID: Translation = { EN: 'Invalid email', CZ: 'Email není validní' };
  public static PHONE: Translation = { EN: 'Phone', CZ: 'Telefon' };
  public static PHONE_PLACEHOLDER: Translation = { EN: '+44 1234 1234', CZ: '+420123456789' };
  public static PHONE_ERROR_MIN_LENGTH: Translation = {
    EN: 'Phone has to have more than 8 numbers', CZ: 'Telefon musí mít více jak 8 číslic',
  };
  public static PHONE_ERROR_MAX_LENGTH: Translation = {
    EN: 'Phone has to have less than 15 numbers', CZ: 'Telefon musí mít méně než 15 číslic',
  };
  public static PERSONAL_INFORMATION_SUCCESS: Translation = {
    EN: 'Personal information successfully saved', CZ: 'Osobní údaje byly úspěšně uloženy',
  };
  public static ORDER_CREATED_SUCCESS: Translation = {
    EN: 'Order successfully created', CZ: 'Objednávka byla úspěšně vytvořena',
  };
  public static PASSWORD_CHANGE: Translation = { EN: 'Password change', CZ: 'Změna hesla' };
  public static PASSWORD_CHANGE_SUCCESS: Translation = { EN: 'Password changed', CZ: 'Změna hesla proběhla úspěšně' };
  public static PASSWORD: Translation = { EN: 'Password', CZ: 'Heslo' };
  public static PASSWORD_PLACEHOLDER: Translation = { EN: 'Insert current password', CZ: 'Vložte aktuální heslo' };
  public static NEW_PASSWORD: Translation = { EN: 'New password', CZ: 'Nové heslo' };
  public static NEW_PASSWORD_PLACEHOLDER: Translation = { EN: 'Insert new password', CZ: 'Vložte nové heslo' };
  public static NEW_PASSWORD_CONFIRMATION: Translation = { EN: 'New password confirmation', CZ: 'Potvrzení nového hesla' };
  public static NEW_PASSWORD_CONFIRMATION_ERROR: Translation = { EN: 'Passwords don\'t match', CZ: 'Hesla se neshodují' };
  public static NEW_PASSWORD_CONFIRMATION_PLACEHOLDER: Translation = { EN: 'Confirm new password', CZ: 'Potvrďte nové heslo' };
  public static CONFIRM_PASSWORD_CHANGE: Translation = { EN: 'Change password', CZ: 'Změnit heslo' };
  public static CONFIRM_PASSWORD_CHANGE_DIALOG: Translation = {
    EN: 'Are you sure about changing your password?', CZ: 'Opravdu si přejete změnit heslo?',
  };
  public static PASSWORD_RULES: Translation = {
    EN: 'Password:', CZ: 'Heslo:',
  };
  public static LENGTH: Translation = {
    EN: 'must have minimum password length: {0}', CZ: 'musí mít minimální délku hesla: {0}',
  };
  public static LENGTH_ERROR: Translation = {
    EN: 'Password too short', CZ: 'Heslo je příliš krátké',
  };
  public static DIGITS: Translation = {
    EN: 'must contain numeric characters: at least {0}', CZ: 'musí obsahovat číselné znaky: alespoň {0}',
  };
  public static DIGITS_ERROR: Translation = {
    EN: 'Password doesn\'t contain any numeric characters', CZ: 'Heslo neobsahuje žádné číselné znaky',
  };
  public static NOT_EMAIL: Translation = {
    EN: 'cannot be the same as your email address', CZ: 'nemůže být stejné jako vaše e-mailová adresa',
  };
  public static NOT_EMAIL_ERROR: Translation = {
    EN: 'Password cannot be the same as your email address', CZ: 'Heslo nemůže být stejné jako vaše e-mailová adresa',
  };
  public static SPECIAL_CHARS: Translation = {
    EN: 'must contain special characters: at least {0}', CZ: 'musí obsahovat speciální znaky: alespoň {0}',
  };
  public static SPECIAL_CHARS_ERROR: Translation = {
    EN: 'Password doesn\'t contain any special characters', CZ: 'Heslo neobsahuje žádné speciální znaky',
  };
  public static UPPER_CASE: Translation = {
    EN: 'must contain upper case letters: at least {0}', CZ: 'musí obsahovat velká písmena: alespoň {0}',
  };
  public static UPPER_CASE_ERROR: Translation = {
    EN: 'Password doesn\'t contain any upper case letters', CZ: 'Heslo neobsahuje žádná velká písmena',
  };
  public static LOWER_CASE: Translation = {
    EN: 'must contain lower case letters: at least {0}', CZ: 'musí obsahovat malá písmena: alespoň {0}',
  };
  public static LOWER_CASE_ERROR: Translation = {
    EN: 'Password doesn\'t contain any lower case letters', CZ: 'Heslo neobsahuje žádná malá písmena',
  };
  public static MAX_LENGTH: Translation = {
    EN: 'cannot exceed characters: {0}', CZ: 'nesmí přesáhnout počet znaků: {0}',
  };
  public static MAX_LENGTH_ERROR: Translation = {
    EN: 'Password too long', CZ: 'Heslo je příliš dlouhé',
  };
}

export default UserSettingsLocale;
