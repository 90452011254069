import React from 'react';
import {
  Box, Button, ListItem, Text, UnorderedList, useDisclosure,
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { Translate } from '@scaut-sro/meepo';
import { PowerOfAttorneyProps } from './PowerOfAttorneyStep.model';
import { downloadFile } from '../../core/service/queries/FileUploadService';
import { POAStatus } from '../../build/generated-sources/enum/POAStatus';
import PowerOfAttorneyStepLocale from './PowerOfAttorneyStep.locale';
import SupportModalDetail from '../../views/SupportModal/SupportModalDetail';

const PowerOfAttorneyStep: React.FunctionComponent<PowerOfAttorneyProps> = (props) => {
  const { powerOfAttorneyEntity } = props;

  const keycloak = useKeycloak();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadPowerOfAttorney = (templateId: number) => {
    downloadFile(keycloak.keycloak.token as string, `${process.env.REACT_APP_API_URL}/dms/getFile/${templateId}`);
  };

  if (!powerOfAttorneyEntity) {
    return (
      <>
        Power Of attorney not yet generated
      </>
    );
  }

  const { poaStatus, generatedTemplates } = powerOfAttorneyEntity;

  return (
    <>
      <Box pt="20px">
        {
            poaStatus === POAStatus.GENERATED && generatedTemplates ? (
              <Box>
                <Text>
                  <Translate label={PowerOfAttorneyStepLocale.INFO_TEXT1} />
                  <Button variant="link" onClick={onOpen}>
                    <Translate label={PowerOfAttorneyStepLocale.INFO_TEXT_SUPPORT} />
                  </Button>
                  <SupportModalDetail
                    onClose={onClose}
                    isOpen={isOpen}
                  />
                </Text>
                <Text mt={4}>
                  <Translate label={PowerOfAttorneyStepLocale.INFO_TEXT2} />
                  {' '}
                  <b>Scaut s.r.o., Kaprova 15/11, 110 00, Praha 1, Česká Republika</b>
                  {' '}
                  <Translate label={PowerOfAttorneyStepLocale.INFO_TEXT3} />
                  {' '}
                  <b><Translate label={PowerOfAttorneyStepLocale.INFO_TEXT4} /></b>
                  {' '}
                  <b>nv3b5kd</b>
                  .
                </Text>
                <UnorderedList padding="10px" margin="10px">
                  {
                  generatedTemplates.map((template) => (
                    <ListItem>
                      <Button variant="link" onClick={() => downloadPowerOfAttorney(template.id as number)}>
                        {template.filename}
                      </Button>
                    </ListItem>
                  ))
                }
                </UnorderedList>
              </Box>
            ) : (
              <>
                Power Of attorney not yet generated
              </>
            )
            }
      </Box>
    </>
  );
};

export default PowerOfAttorneyStep;
