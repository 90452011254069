import React, { useMemo } from 'react';
import {
  DynamicForm, DynamicFormDefinition,
} from '@scaut-sro/meepo';
import { useApolloClient } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import { QuestionnaireProps } from './Questionnaire.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { getSelectRDApi } from '../../core/service/queries/RDApiService';
import MapFormFieldDtoTODynamicFormField from '../../core/util/ObjectMappers';
import { getFileUploadApi } from '../../core/service/queries/FileUploadService';

const Questionnaire: React.FunctionComponent<QuestionnaireProps> = (props) => {
  const {
    definition, readonly, actualValues, dynamicFormProps, scautScreeningComparisonTableApi, sectionNamesMapper,
  } = props;

  const { language } = useGetUser();
  const client = useApolloClient();
  const keycloak = useKeycloak();

  const transformedDefinition = useMemo(() => {
    const newDefinition: DynamicFormDefinition = {};
    Object.keys(definition).forEach((key) => {
      const foundDefinition = definition[key];

      newDefinition[foundDefinition.id || 'undefined'] = MapFormFieldDtoTODynamicFormField(foundDefinition, language);
    });
    return newDefinition;
  }, [definition, language]);

  return (
    <DynamicForm
      {...dynamicFormProps}
      definition={transformedDefinition}
      readonly={readonly}
      actualValues={actualValues}
      selectRDApi={getSelectRDApi(client)}
      hideSectionNames
      fileUploadApi={getFileUploadApi(client, keycloak.keycloak.token as string)}
      scautScreeningComparisonTableApi={scautScreeningComparisonTableApi}
      sectionNamesMapper={sectionNamesMapper}
    />
  );
};

export default Questionnaire;
