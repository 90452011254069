import { Translation } from '../Localization.model';

class CommonLocale {
  public static USER: Translation = { EN: 'User', CZ: 'Uživatel' };
  public static SAVE: Translation = { EN: 'Save', CZ: 'Uložit' };
  public static STORNO: Translation = { EN: 'Storno', CZ: 'Zrušit' };
  public static CLOSE: Translation = { EN: 'Close', CZ: 'Zavřít' };
  public static CANCEL: Translation = { EN: 'Cancel', CZ: 'Zrušit' };
  public static DELETE: Translation = { EN: 'Delete', CZ: 'Smazat' };
  public static DISCARD: Translation = { EN: 'Discard', CZ: 'Zahodit' };
  public static DANGER_ZONE: Translation = { EN: 'Danger zone', CZ: 'Nebezpečná zóna' };
  public static REQUIRED_FIELD: Translation = { EN: 'Required field', CZ: 'Povinné pole' };
  public static NO_VALUE: Translation = { EN: '-No value-', CZ: '-Žádná hodnota-' };
  public static LOADING: Translation = { EN: 'Loading...', CZ: 'Načítám...' };
  public static SEND: Translation = { EN: 'Send', CZ: 'Odeslat' };
}

export default CommonLocale;
