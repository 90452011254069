import { Translation } from '../../core/localization/Localization.model';

class SupportLocale {
  public static MODAL_SUPPORT_HEADER: Translation = { EN: 'Do you need help?', CZ: 'Potřebujete poradit?' };
  public static PHONE_TEXT: Translation = { EN: 'Call us', CZ: 'Zavolejte nám' };
  public static EMAIL_TEXT: Translation = { EN: 'Or send us an e-mail', CZ: 'Nebo nám napište' };
  public static ERROR_GETTING_CONTACT: Translation = {
    EN: 'There was a problem getting contact information',
    CZ: 'Problém při načítání kontaktních údajů',
  };
}

export default SupportLocale;
