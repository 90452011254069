import { GraphQLScalarType, Kind } from 'graphql';
import {TranslationDto} from "../generated-sources/dto/TranslationDto";

export type MAP_PreloadedTranslations = { [key: string]: TranslationDto };

export const Resolver = new GraphQLScalarType({
  name: 'MAP_PreloadedTranslations',
  description: 'MAP_PreloadedTranslations custom scalar type',
  serialize(value) {
    return JSON.stringify(value);
  },
  parseValue(value) {
    const map = new Map<string, string>();
    for (const val in value) {
      map.set(val, value[val]);
    }
    return map;
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
      return {};
    }
    return null;
  },
});
