import { BadgeProps } from '@chakra-ui/layout/dist/types/badge';
import { Translation } from '../../core/localization/Localization.model';

export type StateBadgeProps = {
  state?: string;
  size?: 'md' | 'lg';
  badgeProps?: BadgeProps
};

export type StateBadgeData = {
  locale?: Translation;
  color?: string;
  width?: number;
  fontSize?: string;
  variant?: string;
};

export class StatesLocale {
  public static PROCESSING: Translation = { EN: 'Processing', CZ: 'Zpracovává se' };
  public static WAITING_FOR_CANDIDATE: Translation = { EN: 'Waiting for you', CZ: 'Čeká na Vaší akci' };
  public static FINISHED: Translation = { EN: 'Finished', CZ: 'Hotovo' };
  public static WAITING_FOR_POWER_OF_ATTORNEY: Translation = { EN: 'Waiting for Power of attorney', CZ: 'Čeká na plnou moc' };
  public static CANNOT_COMPLETE: Translation = { EN: 'Cannot complete', CZ: 'Nelze dokončit' };
}
