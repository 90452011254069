import React from 'react';
import {
  LeftMenu, LeftMenuItem,
} from '@scaut-sro/meepo';
import { Link } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import {
  FiHome,
} from 'react-icons/fi';
import { MainMenuProps } from './MainMenu.model';
import Translate from '../Translate/Translate';
import MainMenuLocale from '../../core/localization/translations/MainMenu.locale';

const MainMenu: React.FunctionComponent<MainMenuProps> = (props) => {
  const {
    menuIsSmall, leftMenuWidth, headerHeight, activeItem, handleSetActiveItem,
  } = props;

  return (
    <LeftMenu
      leftMenuWidth={leftMenuWidth}
      headerHeight={headerHeight}
      isMenuSmall={menuIsSmall}
    >
      <Flex
        flexDir="column"
        justifyContent="space-between"
        h={`calc( 100vh - ${headerHeight} )`}
        pb={8}
      >
        <Flex
          mt={6}
          flexDir="column"
        >
          <Link to="/">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="overview"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<FiHome />}
            >
              <Translate label={MainMenuLocale.OVERVIEW} />
            </LeftMenuItem>
          </Link>
        </Flex>
        { /* <Box>
          <Flex
            flexDir="column"
          >
            <SupportModal
              activeItem={activeItem}
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
            />
          </Flex>
          <Flex
            flexDir="column"
          >
            <FaqModal
              activeItem={activeItem}
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
            />
          </Flex>
        </Box> */ }
      </Flex>
    </LeftMenu>
  );
};

export default MainMenu;
