import { Translation } from '../Localization.model';

class MainMenuLocale {
  public static OVERVIEW: Translation = { EN: 'Overview', CZ: 'Přehled' };
  public static FORMS: Translation = { EN: 'Forms', CZ: 'Formuláře' };
  public static SUPPORT: Translation = { EN: 'Support', CZ: 'Podpora' };
  public static FAQ: Translation = { EN: 'FAQ', CZ: 'FAQ' };
}

export default MainMenuLocale;
