import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Center, Flex, Spacer, Text,
} from '@chakra-ui/react';
import {
  dynamicFormPresets, SectionContainer, StepperTabs, translate, Translation,
} from '@scaut-sro/meepo';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FiArrowLeft } from 'react-icons/all';
import { DynamicFormSectionDefinition } from '@scaut-sro/meepo/lib/components/DynamicForm/DynamicForm.model';
import FormLocale from '../../core/localization/translations/Form.locale';
import Translate from '../../components/Translate/Translate';
import { FormDetailProps, FormStage } from './Forms.model';
import Questionnaire from '../../components/Questionnaire/Questionnaire';
import FormsLocale from './Forms.locale';
import { QuestionnaireDefinition } from '../../components/Questionnaire/Questionnaire.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { DynamicForm } from '../../build/generated-sources/dto/DynamicForm';
import PowerOfAttorneyStep from '../../components/PowerOfAttorneyStep/PowerOfAttorneyStep';

type SectionPartForm = {
  [key:number]: QuestionnaireDefinition
};

type IndexedDefinition = {
  index: number,
  definition: QuestionnaireDefinition
};

type SectionPage = {
  formsMap: SectionPartForm,
  formsArray: IndexedDefinition[],
  section: string
};

type SectionPageMap = {
  [key:string]: SectionPage
};

const FormDetail: React.FunctionComponent<FormDetailProps> = (props) => {
  const {
    candidateFormDto, onFormSend, formIsLoading,
  } = props;

  const useFormMethods = useForm();

  const handleScreeningFormSubmit: SubmitHandler<any> = (values) => {
    if (onFormSend) {
      onFormSend(candidateFormDto?.form?.id || -1, values);
    }
  };

  const form = useMemo(() => (candidateFormDto?.form?.form), [candidateFormDto]);
  const [page, setPage] = useState(0);

  const sortSections = ((section1: string, section2: string) => {
    const obj1index = dynamicFormPresets[section1] ? dynamicFormPresets[section1].index : 100;
    const obj2index = dynamicFormPresets[section2] ? dynamicFormPresets[section2].index : 100;

    if (obj1index > obj2index) {
      return 1;
    }

    if (obj1index < obj2index) {
      return -1;
    }

    return 0;
  });

  const includesPowerOfAttorney = useMemo(() => {
    if (candidateFormDto?.powerOfAttorney && candidateFormDto?.powerOfAttorney?.poaStatus !== 'WONT_GENERATE') {
      return true;
    }
    return false;
  }, [candidateFormDto?.powerOfAttorney]);

  const isFormEmpty = (dynamicForm: DynamicForm | undefined) => !dynamicForm?.form || Object.keys(dynamicForm.form).length === 0;

  const isFilledOutSectionEmpty = (sections: string[] | undefined) => !sections || sections.length === 0;

  const formStage: FormStage = useMemo(() => {
    if (!isFormEmpty(candidateFormDto?.form) && !isFilledOutSectionEmpty(candidateFormDto?.filledOutSections)) {
      return FormStage.ADDITIONAL_INFO;
    } if (isFormEmpty(candidateFormDto?.form)
        && !isFilledOutSectionEmpty(candidateFormDto?.filledOutSections)
        && includesPowerOfAttorney) {
      return FormStage.POWER_OF_ATTORNEY;
    }
    return FormStage.INITIAL_FORM;
  }, [candidateFormDto?.form, candidateFormDto?.filledOutSections, includesPowerOfAttorney]);

  const { language } = useGetUser();

  const sectionPages: SectionPage[] = useMemo(() => {
    const sectionPageMap: SectionPageMap = {};
    Object.entries(form || []).forEach(([, value]) => {
      const { section } = value.properties;
      if (!sectionPageMap[section]) {
        sectionPageMap[section] = {
          section,
          formsMap: {},
          formsArray: [],
        };
      }

      const index = value.properties.index ? +value.properties.index : 0;
      if (!sectionPageMap[section].formsMap[index]) {
        sectionPageMap[section].formsMap[index] = {};
      }

      sectionPageMap[section].formsMap[index][value.id] = value;
    });

    const resultPages: SectionPage[] = Object.keys(sectionPageMap).map((section) => sectionPageMap[section]);

    const sortIndexedDefinitions = (array: IndexedDefinition[]) => array.sort((obj1, obj2) => {
      if (obj1.index > obj2.index) {
        return 1;
      }

      if (obj1.index < obj2.index) {
        return -1;
      }

      return 0;
    });

    resultPages.forEach((section) => {
      const formArray: IndexedDefinition[] = Object.keys(section.formsMap).map((indexKey) => ({
        index: +indexKey,
        definition: section.formsMap[+indexKey],
      }));

      // eslint-disable-next-line no-param-reassign
      section.formsArray = sortIndexedDefinitions(formArray);
    });

    const sortDefinedArray = (array: SectionPage[]) => array.sort((obj1, obj2) => sortSections(obj1.section, obj2.section));
    return sortDefinedArray(resultPages);
  }, [form]);

  const handlePageUp = () => {
    useFormMethods.trigger(undefined, { shouldFocus: true }).then((response) => {
      if (response) {
        setPage(page + 1);
      }
    });
  };

  const handlePageDown = () => {
    setPage(page - 1);
  };

  const [actualValues, setActualValues] = useState<{ [p: string]: any }>({});
  const handleFormChange = () => {
    setActualValues(useFormMethods.getValues());
  };

  useEffect(() => {
    setActualValues(useFormMethods.getValues());
  }, [useFormMethods, setActualValues]);

  const formSectionTypesTranslation: { [key: string]: any } = useMemo(() => {
    const translations: { [key: string]: any } = {};

    Object.keys(dynamicFormPresets)
      .forEach((sectionName) => {
        translations[sectionName] = translate(dynamicFormPresets[sectionName].translation as Translation, language);
      });

    translations.POWER_OF_ATTORNEY = translate(FormsLocale.POWER_OF_ATTORNEY, language);
    translations.ADDITIONAL_INFO = translate(FormsLocale.ADDITIONAL_INFO, language);

    return translations;
  }, [language]);

  const translatedSections = useMemo(() => {
    const sections = sectionPages.map((pageSection) => formSectionTypesTranslation[pageSection.section]);
    if (formStage === FormStage.ADDITIONAL_INFO || formStage === FormStage.POWER_OF_ATTORNEY) {
      if (candidateFormDto?.filledOutSections) {
        [...candidateFormDto?.filledOutSections].sort(sortSections).forEach((filledOutSection) => {
          sections.push(formSectionTypesTranslation[filledOutSection]);
        });
      }
      sections.push(formSectionTypesTranslation.ADDITIONAL_INFO);
      if (includesPowerOfAttorney) {
        sections.push(formSectionTypesTranslation.POWER_OF_ATTORNEY);
      }
    }
    return sections;
  },
  [formSectionTypesTranslation, sectionPages, includesPowerOfAttorney, candidateFormDto?.filledOutSections, formStage]);

  useEffect(() => {
    if (formStage === FormStage.ADDITIONAL_INFO) {
      if (includesPowerOfAttorney) {
        setPage(translatedSections.length - 2);
      } else {
        setPage(translatedSections.length - 1);
      }
    } else if (formStage === FormStage.POWER_OF_ATTORNEY) {
      setPage(translatedSections.length - 1);
    }
  }, [candidateFormDto?.form,
    candidateFormDto?.filledOutSections,
    candidateFormDto?.powerOfAttorney,
    translatedSections,
    formStage,
    includesPowerOfAttorney,
  ]);

  const translateIndexedSection = (sectionName: string, index: number): string => {
    const preset: DynamicFormSectionDefinition = dynamicFormPresets[sectionName];
    // @ts-ignore
    return `${preset.translation[language]} ${index + 1}`;
  };

  return (
    <Box>
      { sectionPages
        ? (
          <SectionContainer>
            <Box>
              <Box width="80%" margin="30px auto">
                <Box
                  height={candidateFormDto?.jobPosition ? '150px' : '88px'}
                  background="#EEEFF3"
                  padding="20px"
                  paddingTop="10px"
                  marginBottom="20px"
                >
                  <Text lineHeight="180%">
                    {
                      candidateFormDto?.jobPosition ? (
                        <>
                          <Text fontSize="xs" textTransform="uppercase" pl="20px" mt="20px">
                            <Translate label={FormLocale.POSITION} />
                          </Text>
                          <Text fontSize="2xl" fontWeight="bold" pl="20px">
                            {candidateFormDto?.jobPosition}
                          </Text>
                        </>
                      ) : undefined
                    }

                    <Text fontSize="md" textTransform="uppercase" pl="20px" pt="10px">
                      <Translate label={FormLocale.EMPLOYER} />
                    </Text>
                    <Text fontSize="lg" fontWeight="bold" pl="20px">
                      {candidateFormDto?.client?.name}
                    </Text>
                  </Text>
                </Box>
                <StepperTabs
                  steps={translatedSections}
                  currentStep={page}
                  // size="lg"
                  colorScheme="#0066F8"
                  key={page}
                />
                <FormProvider {...useFormMethods}>
                  <form onSubmit={useFormMethods.handleSubmit(handleScreeningFormSubmit)} onChange={handleFormChange}>
                    {
                      formStage === FormStage.INITIAL_FORM && sectionPages[page] ? (
                        sectionPages[page].formsArray.map((indexedDefinition, index) => (
                          <Questionnaire
                            definition={indexedDefinition.definition}
                            actualValues={actualValues}
                            sectionNamesMapper={
                              sectionPages[page].formsArray.length > 1
                                ? (sectionName) => translateIndexedSection(sectionName, index)
                                : undefined
                            }
                            dynamicFormProps={{
                              styleProps: {
                                margin: '10px',
                                padding: '20px',
                              },
                            }}
                          />
                        ))
                      ) : undefined
                    }
                    {
                      formStage === FormStage.ADDITIONAL_INFO ? (
                        <Questionnaire
                          definition={candidateFormDto?.form?.form as QuestionnaireDefinition}
                          actualValues={actualValues}
                          dynamicFormProps={{
                            styleProps: {
                              margin: '10px',
                              padding: '20px',
                            },
                          }}
                        />
                      ) : undefined
                    }
                    {
                      formStage === FormStage.POWER_OF_ATTORNEY ? (
                        <PowerOfAttorneyStep powerOfAttorneyEntity={candidateFormDto?.powerOfAttorney} />
                      ) : undefined
                    }
                    <Spacer />
                    <Flex justifyContent={page > 0 ? 'space-between' : 'right'}>
                      {(page > 0) && (
                      <Button
                        mt={6}
                        ml="30px"
                        pl={0}
                        type="button"
                        variant="ghost"
                        size="lg"
                        isLoading={formIsLoading}
                        onClick={handlePageDown}
                      >
                        <FiArrowLeft />
                        <Text pl="10px">
                          <Translate label={FormLocale.BACK} />
                        </Text>
                      </Button>
                      )}
                      {(page === sectionPages.length - 1 || formStage === FormStage.ADDITIONAL_INFO) && (
                      <Button
                        mt={6}
                        mr="30px"
                        type="submit"
                        size="lg"
                        paddingRight="50px"
                        paddingLeft="50px"
                        isLoading={formIsLoading}
                      >
                        <Translate label={FormLocale.SEND} />
                      </Button>
                      )}
                      {(page < sectionPages.length - 1) && (
                      <Button
                        mt={6}
                        mr="30px"
                        type="button"
                        variant="solid"
                        size="lg"
                        paddingRight="50px"
                        paddingLeft="50px"
                        isLoading={formIsLoading}
                        onClick={handlePageUp}
                      >
                        <Translate label={FormLocale.CONTINUE} />
                      </Button>
                      )}
                    </Flex>
                  </form>
                </FormProvider>
              </Box>
              {/* <Box */}
              {/*  height="60px" */}
              {/*  bg="#EEEFF3" */}
              {/*  paddingLeft="30px" */}
              {/*  paddingRight="30px" */}
              {/*  display="flex" */}
              {/*  alignItems="center" */}
              {/* > */}
              {/*  <Box */}
              {/*      display="flex" */}
              {/*      margin="0 auto" */}
              {/*      width="85%" */}
              {/*      justifyContent="space-between" */}
              {/*      alignItems="center" */}
              {/*      padding="40px" */}
              {/*  > */}
              {/*    <Box> */}
              {/*      <HStack> */}
              {/*        <Text pr="5px" color="gray.500" fontSize="md">Powered by </Text> */}
              {/*        <Flex */}
              {/*          stroke="primaryBase" */}
              {/*          fill="primaryBase" */}
              {/*          h="100%" */}
              {/*          alignItems="center" */}
              {/*        > */}
              {/*          <ScautIcon icon="SiLogo" size="20px" /> */}
              {/*        </Flex> */}
              {/*      </HStack> */}
              {/*    </Box> */}
              {/*    <Box> */}
              {/*      <Text color="gray.500" fontSize="md"><Translate label={FormLocale.DATA_PROTECTION} /></Text> */}
              {/*    </Box> */}
              {/*  </Box> */}
              {/* </Box> */}
            </Box>
          </SectionContainer>
        )
        : (
          <Center>
            <Translate label={FormsLocale.NO_MANUAL_TASKS} />
          </Center>
        )}
    </Box>
  );
};

export default FormDetail;
