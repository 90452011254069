import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache,
} from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { KeycloakInstance } from 'keycloak-js';
import TagManager from 'react-gtm-module';
import debounce from 'lodash.debounce';
import { setContext } from '@apollo/client/link/context';
import keycloakClient from './keycloak';
import ScautCandidateTheme from './theme/ScautCandidateTheme';
import AppStore from './core/store/DefaultStore';
import { useUserSettingsReduce } from './core/store/reducers/UserSettingsReducer';
import AppLayout from './components/AppLayout/AppLayout';
import LoadingScreen from './views/LoadingScreen/LoadingScreen';
import { schema } from './build/generated-sources/scalars/ScalarConstants';
import { useGetUserProfile } from './build/generated-sources/service/QueryService';
import UserSettings from './views/UserSettings/UserSettings';
import Overview from './views/Overview/Overview';
import NoMatch from './views/404/404';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ScautCandidateRoles from './core/auth/ScautCandidateRoles';
import { Language } from './build/generated-sources/enum/Language';
import FormDetailPage from './views/Forms/FormDetailPage';

const Root: React.FunctionComponent = ({ children }) => {
  const { setUserProfile, setLanguage } = useUserSettingsReduce();
  const { loading } = useGetUserProfile({
    firstName: true,
    lastName: true,
    email: true,
    avatar: true,
    userPreferences: {
      language: true,
    },
  }, {
    onCompleted: (data) => {
      setUserProfile(data.userProfile);
      const lang: Language = data.userProfile.userPreferences?.language || Language.EN;
      setLanguage(lang);
    },
  });

  return (
    <>
      {loading ? <LoadingScreen /> : <AppLayout>{ children }</AppLayout>}
    </>
  );
};

interface ConnectionWrapperProps {
  keycloak?: KeycloakInstance,
  initialized?: boolean
}

const ConnectionWrapper: React.FunctionComponent<ConnectionWrapperProps> = (
  {
    children,
    keycloak,
    initialized,
  },
) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const logoutDebounce = keycloak ? debounce(() => {
    keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  }, 3600000) : undefined;

  const authLink = setContext(async (_, { headers }) => {
    try {
      await keycloak?.updateToken(1);
      if (logoutDebounce) {
        logoutDebounce();
      }
      const authorizationHeader = initialized ? { authorization: `Bearer ${keycloak?.token}` } : {};
      return {
        headers: {
          ...headers,
          ...authorizationHeader,
        },
      };
    } catch (error) {
      keycloak?.logout();
      return undefined;
    }
  });

  const link = ApolloLink.from([
    withScalars({ schema }),
    authLink,
    httpLink,
  ].concat());

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache(),
    link,
  });

  return (
    <ApolloProvider client={client}>
      { children }
    </ApolloProvider>
  );
};

ConnectionWrapper.defaultProps = {
  initialized: false,
  keycloak: undefined,
};

const AuthorizedConnectionWrapper: React.FunctionComponent = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  return (
    <>
      { initialized ? (
        <ConnectionWrapper initialized={initialized} keycloak={keycloak}>
          { children }
        </ConnectionWrapper>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

const PrivateApp: React.FunctionComponent = ({ children }) => (
  <ReactKeycloakProvider
    authClient={keycloakClient}
    initOptions={{ onLoad: 'login-required' }}
  >
    <AuthorizedConnectionWrapper>
      <Root>
        { children }
      </Root>
    </AuthorizedConnectionWrapper>
  </ReactKeycloakProvider>
);

function App() {
  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_TAG || '' });
  }, []);

  return (
    <Provider store={AppStore}>
      <ChakraProvider theme={ScautCandidateTheme}>
        <Router>
          <Switch>
            <Route path="*">
              <PrivateApp>
                <Switch>
                  <PrivateRoute path="/form/:id" requiredRoles={[ScautCandidateRoles.REGULAR]}>
                    <FormDetailPage />
                  </PrivateRoute>
                  <PrivateRoute path="/user-settings" requiredRoles={[ScautCandidateRoles.REGULAR]}>
                    <UserSettings />
                  </PrivateRoute>
                  <PrivateRoute exact path="/" requiredRoles={[ScautCandidateRoles.REGULAR]}>
                    <Overview />
                  </PrivateRoute>
                  <PrivateRoute path="*" requiredRoles={[ScautCandidateRoles.REGULAR]}>
                    <NoMatch />
                  </PrivateRoute>
                </Switch>
              </PrivateApp>
            </Route>
          </Switch>
        </Router>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
