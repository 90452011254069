import React from 'react';
import { Box, Center, Spinner } from '@chakra-ui/react';
import ScautLogo from '../../components/Logo/Logo';

const LoadingScreen: React.FunctionComponent = () => (
  <>
    <Center flexDirection="column" h="90vh">
      <Spinner
        width="5em"
        height="5em"
        color="primaryBase"
        thickness="5px"
      />
      <Box mt={10}>
        <Box mt={-1}><ScautLogo /></Box>
      </Box>
    </Center>
  </>
);
export default LoadingScreen;
