import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { SectionContainer, Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { FormDetailProps } from './Forms.model';
import FormDetail from './FormDetail';
import { useLazyGetGetForm } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import FormsLocale from './Forms.locale';
import { CandidateFormDto } from '../../build/generated-sources/dto/CandidateFormDto';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { useFillForm } from '../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

const FormDetailPage: React.FunctionComponent<FormDetailProps> = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const [candidateFormDto, setCandidateFormDto] = useState<CandidateFormDto>();
  const { language } = useGetUser();
  const orderId = Number.isNaN(+params.id);
  const history = useHistory();

  const [ordersFormHandle] = useLazyGetGetForm(
    {
      orderId: true,
      client: {
        name: true,
      },
      jobPosition: true,
      form: {
        id: true,
        form: true,
      },
      powerOfAttorney: {
        generatedTemplates: {
          id: true,
          filename: true,
        },
        poaStatus: true,
      },
      filledOutSections: true,
    },
    {
      onCompleted: (response) => {
        setCandidateFormDto(response.getForm);
        if (response.getForm.powerOfAttorney?.poaStatus === 'WONT_GENERATE') {
          history.push('/');
        }
      },
      onError: () => {
        Toast({
          title: translate(FormsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        orderId: +params.id,
      },
    },
  );

  const [mutateFillForm, mutateFillFormOptions] = useFillForm(
    {
      generalValidationMessages: true,
      invalidFields: {
        fieldId: true,
        isValid: true,
        validationMessages: true,
      },
      isValid: true,
    },
    {
      onCompleted: (response) => {
        if (!response.fillForm.isValid) {
          // eslint-disable-next-line no-console
          console.error(response.fillForm);
        }
        Toast({
          title: translate(FormsLocale.COMPLETE_TASK_SUCCESS, language),
          status: 'success',
        });
        ordersFormHandle({
          variables: {
            orderId: +params.id,
          },
        });

        // if (candidateFormDto?.powerOfAttorney?.generatedTemplates?.length === 0) {
        //   history.push('/');
        // }
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  useEffect(() => {
    if (!orderId) {
      ordersFormHandle({
        variables: {
          orderId: +params.id,
        },
      });
    }
  }, [ordersFormHandle, params.id, orderId]);

  const onFormSend = (formId: number, values: any) => {
    mutateFillForm({
      variables: {
        formId,
        formData: values,
      },
    });
  };

  return (
    <>
      {candidateFormDto ? (
        <SectionContainer>
          <FormDetail candidateFormDto={candidateFormDto} onFormSend={onFormSend} formIsLoading={mutateFillFormOptions.loading} />
        </SectionContainer>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default FormDetailPage;
