import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { FileUploadApi } from '@scaut-sro/meepo/lib/components/DynamicForm/DynamicForm.model';
import {
  FileDeleteHandlerResponse,
  FilesInfoGetterResponse,
  FileUploadHandlerResponse,
} from '@scaut-sro/meepo/lib/components/FileUpload/FileUpload.model';
import axios, { AxiosRequestConfig } from 'axios';
import { GraphqlBuilder, GraphQLType } from '@scaut-sro/npm-core-generator';
import { FetchResult } from '@apollo/client/link/core';
import { downloadBlob } from '@scaut-sro/meepo';
import { FileInfoMapper } from '../../../build/generated-sources/dto/FileInfo';
import {
  UseGetGetFilesInfoData,
  UseGetGetFilesInfoVariables,
  UseGetGetUploadedFilesInfoData,
  UseGetGetUploadedFilesInfoVariables,
} from '../../../build/generated-sources/service/QueryServiceModel';
import { FileDeleteHandlerResponseMapper } from '../../../build/generated-sources/dto/FileDeleteHandlerResponse';
import { UseDeleteFileData, UseDeleteFileVariables } from '../../../build/generated-sources/service/MutationServiceModel';

export function updateAvatar(file: File, token: String): Promise<any> {
  const formData = new FormData();
  formData.append('file', file);

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${process.env.REACT_APP_API_URL}/dms/updateAvatar`, formData, config);
}

export function deleteFile(
  client: ApolloClient<any>,
  mapper: FileDeleteHandlerResponseMapper,
  variables: UseDeleteFileVariables,
): Promise<FetchResult<UseDeleteFileData>> {
  const mutationBuilder: GraphqlBuilder<FileDeleteHandlerResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  const mutation = mutationBuilder
    .setName('deleteFile')
    .setReturnProperties(mapper)
    .declareVariables({
      id: 'Long!',
    })
    .setProperties(
      'id: $id',
    )
    .build();

  return client.mutate<UseDeleteFileData, UseDeleteFileVariables>({
    mutation,
    variables,
    fetchPolicy: 'no-cache',
  });
}

export function getUploadedFilesInfo(
  client: ApolloClient<any>,
  mapper: FileInfoMapper,
  variables: UseGetGetUploadedFilesInfoVariables,
): Promise<ApolloQueryResult<UseGetGetUploadedFilesInfoData>> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('getUploadedFilesInfo')
    .setReturnProperties(mapper)
    .declareVariables({
      fileUploadId: 'String!',
    })
    .setProperties('fileUploadId: $fileUploadId')
    .build();

  return client.query<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>({
    query,
    variables,
    fetchPolicy: 'no-cache',
  });
}

export function getFilesInfo(
  client: ApolloClient<any>,
  mapper: FileInfoMapper,
  variables: UseGetGetFilesInfoVariables,
): Promise<ApolloQueryResult<UseGetGetFilesInfoData>> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('getFilesInfo')
    .setReturnProperties(mapper)
    .declareVariables({
      ids: '[Long]',
    })
    .setProperties('ids: $ids')
    .build();

  return client.query<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>({
    query,
    variables,
    fetchPolicy: 'no-cache',
  });
}

export function getFileUploadApi(client: ApolloClient<any>, token: string): FileUploadApi {
  return {
    // @ts-ignore
    onFileUpload: (file: File, fileUploadId: string): Promise<FileUploadHandlerResponse> => {
      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
      return axios.post(`${process.env.REACT_APP_API_URL}/dms/uploadFile/${fileUploadId}`, formData, config);
    },
    onFileSign: (blob: Blob, fileUploadId: string, templateId: number): Promise<{
      status: number;
      message: string;
    }> => {
      const formData = new FormData();
      const file = new File([blob], 'name', {
        type: 'image/png',
      });
      // eslint-disable-next-line no-console
      console.error(file.type);

      formData.append('file', file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
      return axios.post(`${process.env.REACT_APP_API_URL}/dms/sign/${fileUploadId}/${templateId}`, formData, config);
    },
    onFileDelete: async (id: number): Promise<FileDeleteHandlerResponse> => {
      const onFileDelete = async (): Promise<FileDeleteHandlerResponse> => {
        const result = await deleteFile(client, {
          status: true,
          message: true,
        }, {
          id,
        });

        return new Promise<FileDeleteHandlerResponse>((resolve) => {
          const failedResponse: FileDeleteHandlerResponse = {
            status: -1,
            message: 'NOK',
          };

          if (!result || !result.data) {
            resolve(failedResponse);
          } else {
            const response: FileDeleteHandlerResponse = {
              status: result.data.deleteFile?.status as number,
              message: result?.data.deleteFile?.message as string,
            };
            resolve(response);
          }
        });
        return new Promise<FileDeleteHandlerResponse>(() => result);
      };

      return onFileDelete();
    },
    filesInfoGetter: (ids: number[]): Promise<FilesInfoGetterResponse> => {
      const filesInfoGetter = async (): Promise<FilesInfoGetterResponse> => {
        const result = await getFilesInfo(client, {
          id: true,
          name: true,
        }, {
          ids,
        });

        return new Promise<FilesInfoGetterResponse>((resolve) => {
          const response: FilesInfoGetterResponse = result.data.getFilesInfo.map((fileInfo) => ({
            id: fileInfo.id as number,
            name: fileInfo.name as string,
          }));
          resolve(response);
        });
      };

      return filesInfoGetter();
    },
    uploadedFilesInfoGetter: (fileUploadId: string): Promise<FilesInfoGetterResponse> => {
      const uploadedFilesInfoGetter = async (): Promise<FilesInfoGetterResponse> => {
        const result = await getUploadedFilesInfo(client, {
          id: true,
          name: true,
        }, {
          fileUploadId,
        });

        return new Promise<FilesInfoGetterResponse>((resolve) => {
          const response: FilesInfoGetterResponse = result.data.getUploadedFilesInfo.map((fileInfo) => ({
            id: fileInfo.id as number,
            name: fileInfo.name as string,
          }));
          resolve(response);
        });
      };

      return uploadedFilesInfoGetter();
    },
    callback: (): void => {
    },
    onFileDownload: (id: number): Promise<Blob> => {
      const onFileDownload = async () => {
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        };
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/dms/getFile/${id}`, config);

        return new Promise<Blob>((resolve) => {
          resolve(result.data);
        });
      };

      return onFileDownload();
    },
  };
}

export const downloadFile = async (token: string, url: string) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };

  const result = await axios.get(url, config);
  let filename: string | undefined;
  if (result.headers['content-disposition']) {
    const dispositionParts = result.headers['content-disposition'].split(';');

    dispositionParts.forEach((part) => {
      if (!filename && part.includes('filename=')) {
        const filenameSplit = part.split('filename=');
        if (filenameSplit.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          filename = filenameSplit[0];
        } else if (filenameSplit.length === 2) {
          // eslint-disable-next-line prefer-destructuring
          filename = filenameSplit[1];
        }
      }
    });
  }
  downloadBlob(result.data, filename);
};
